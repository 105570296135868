<template>
  <section class="aliens">
    <div class="aliens__buttons">
      <a @click="scrollToEL('who-are-aliens')">
        <button>Who are aLIEnS?</button>
      </a>
      <!--      <a @click="scrollToEL('roadmap')">-->
      <!--        <button>Roadmap 1.0</button>-->
      <!--      </a>-->
      <a @click="scrollToEL('drops')">
        <button class="button-bounce">Drops & Pricing</button>
      </a>
      <a @click="scrollToEL('technical')">
        <button>Technical stuff</button>
      </a>
      <a @click="scrollToEL('faq')">
        <button>FAQ</button>
      </a>
    </div>
    <section class="roadmap-image">
      <img src="../../../assets/roadmap1.png" alt="roadmap 1.0">
    </section>
    <section class="aliens__article" id="who-are-aliens">
      <h1>Who are the aLIEnS?</h1>
      <p>aLIEnS are the first ever animated collectibles series dedicated to aliens, created by cryptographic artist
        Buzzle. They are limited 1,000 unique collectible pieces living on „Ethereum’s Internet of Blockchains”-
        <a href="polygon.technology" target="_blank"> Polygon</a>. Each of them is a Ethereum-compatible Collectible
        that comes with proof of ownership. For technical
        details about aLIEnS scroll down to the „Technical stuff” section or just click the button above.</p>
      <p>aLIEnS arrived from the farthest ends of the universe aiming to bring back the nostalgia of the 80s and Golden
        Age of Arcade Games with their timeless retro style.</p>
      <p>Every aLIEn has its own individual recognition code. It consists of a sequence of 12 digits. Each digit
        corresponds to a specific element in the feature. An example individual code looks like this:</p>
      <p>00 11 2 3 4 5 6 7 8 9</p>
      <p>aLIEnS are created with 10 traits. Each feature is treated by an algorithm. It makes sure that the trait occur
        in the collection in a certain percentage. Some features are used in the collection in a fixed percentage.
        Others are used less often than the rest. Below is detailed list of traits and their percentage of occurrence in
        aLIEnS collection.</p>
      <p>Considering all the features, there are 10 billion combinations of how 1 Collectible piece can look and sound.
        None of them are the same, and each can be owned by only one person.</p>
      <p>aLIEnS are one of the most intense examples of a „Non-Fungible Token” on „Ethereum’s Internet of Blockchains”-
        Polygon blockchain.</p>

    </section>
    <section class="aliens__article">
      <h1>1. BACKGROUND</h1>
      <p>There are 100 different backgrounds. All are selected archival images from publicly available NASA databases.
        All photos are pixelated at the graphic design stage. Then, at the animation stage, motion effects such as
        distortion (flicker and pixelbox) are applied.</p>
      <p>Trait is evenly distributed throughout the collection. The artist makes sure that the Fisher-Yates shuffling
        algorithm randomly assigns 1 background to a given drop (100 Collectibles). 1 background is used exactly 10
        times in the entire collection (1 per DROP), so that 1% of the collection has a given background feature.</p>
      <p>The exact numbering of each element of this feature their appearance can be found below.</p>
      <img src="../../../assets/aliens-background.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>2. TEXTURE</h1>
      <p>There are 100 textures in the collection, all of which are randomly selected from database by an algorithm. At
        the graphic design stage, each texture is shaped and treated with an oil effect. Then, wave motion effects are
        applied- at the animation stage.</p>
      <p> Trait is evenly distributed throughout the collection. The artist makes sure that the Fisher-Yates shuffling
        algorithm randomly assigns 1 texture to a given drop (100 Collectibles). 1 texture is used exactly 10 times in
        the entire collection (1 per DROP), so that 1% of the collection has a given texture feature.</p>
      <p> The exact numbering of each element of this feature and their appearance can be found below.</p>
      <img src="../../../assets/aliens-texture.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>3. BODY</h1>
      <p>Every aLIEn has the same body contour. Combined with individual eyes, nose and mouth, no two aliens are the
        same. The outline is picked by artist from database and prepared at the graphic design stage. Then at the
        animation stage, the laser effect and motion effect are applied.</p>
      <p>Appearance of this feature can be found below.</p>
      <img src="../../../assets/aliens-body.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>4. EYES</h1>
      <p>There are 10 pairs of eyes in the collection, all of which are picked from database and carefully prepared by
        the artist. Each pair of eyes is prepared at the graphic design stage. Then, laser effects are applied at the
        animation stage.</p>
      <p>The trait is equally distributed in the collection, so the algorithm ensures that one pair of eyes is used
        exactly 100 times in the collection, making 10% of the collection have a given pair of eyes. The eye pairs (10),
        along with the noses (10) and mouths (10) together form 1,000 combinations of what the alien's outline might
        look like. This has a direct effect on the uniqueness of each one.</p>
      <p>The exact numbering of the individual elements of this feature and their appearance can be found below.</p>
      <img src="../../../assets/aliens-eyes.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>5. NOSE</h1>
      <p>There are 10 noses in the collection, all of which are picked from database and carefully prepared by the
        artist. Each nose is prepared at the graphic design stage. Then, laser effects are applied at the animation
        stage.</p>
      <p>The trait occurs equally in the collection, so the algorithm ensures that one nose is used exactly 100
        times in the collection, making 10% of the collection have the given nose. The noses (10), along with the
        eyes (10) and mouth (10) together create 1,000 combinations of what the aliene’s outline might look like.
        This has a direct effect on the uniqueness of each one.</p>
      <p>The exact numbering of the individual elements of this feature and their appearance can be found below.</p>
      <img src="../../../assets/aliens-nose.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>6. MOUTH</h1>
      <p>There are 10 mouths in the collection, all of which are picked from database and carefully prepared by the
        artist. Each mouth is prepared at the graphic design stage. Then, laser effects are applied at the animation
        stage.</p>
      <p>The feature occurs equally in the collection, so the algorithm ensures that one mouth is used exactly 100 times
        in the collection, making 10% of the collection have the given mouth. Lips(10), along with eyes (10) and noses
        (10) together create 1,000 combinations of what an aliene’s outline might look like. This has a direct effect on
        the uniqueness of each one.</p>
      <p>The exact numbering of the individual elements of this feature and their appearance can be found below.</p>
      <img src="../../../assets/aliens-mouth.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>7. COLOR</h1>
      <p>There are 10 colors in the collection, all of which are randomly selected by artist- at the graphic design
        stage. Then, at the animation stage, the laser effects on the outline are of a particular color. The rarity of
        the colors in the collection depends on the texture that was used in a particular artwork.</p>
      <p></p>
      <p>This feature occurs very unevenly in the set. The artist makes sure that the contrast between the texture and
        the outline is appropriate. The algorithm assigns specific color groups to specific textures with DROP. The
        percentage of occurrence of given colors in the set can be represented as follows:</p>
      <p>GREEN 14%</p>
      <p>YELLOW 21%</p>
      <p>BLUE 11%</p>
      <p>VIOLET 8%</p>
      <p>TURQUOISE 2%</p>
      <p>WHITE 7%</p>
      <p>PINK 6%</p>
      <p>AZURE 8%</p>
      <p>ORANGE 10%</p>
      <p>RED 13%</p>
      <p>The exact numbering of the individual elements of this feature and their appearance can be found below.</p>
      <img src="../../../assets/aliens-color.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>8. SOUNDS FROM OUTER SPACE</h1>
      <p>There are 10 sounds from space in the collection. Among them there are 9 planet sounds and 1 star sound.
      </p>
      <p>This feature appears unevenly in the collection. Some sounds are used less frequently than others-according to
        BUZZLE’s artistic vision. The algorithm ensures that one sound is used a certain number of times in the
        collection, making a given percentage of the collection have the sound of a particular planet or star.</p>
      <p>EARTH 14%</p>
      <p>JUPITER 8%</p>
      <p>MARS 10%</p>
      <p>SATURN 7%</p>
      <p>MERKURY 10%</p>
      <p>NEPTUN 13%</p>
      <p>PLUTO 11%</p>
      <p>URANUS 12%</p>
      <p>SUN 6%</p>
      <p>VENUS 9%</p>
      <p>The exact numbering of each element of this feature is shown below.</p>
      <img src="../../../assets/aliens-sound.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>9. FREQUENCY</h1>
      <p>There are 10 sound frequencies in the collection.
      </p>
      <p>This feature appears unevenly in the collection. Some frequencies are used less than others – in keeping with
        BUZZLE’s artistic vision. The algorithm ensures that one frequency is used a certain number of times in the
        collection, so that a given percentage of the collection has the particular frequency.</p>
      <p>40hz 5%</p>
      <p>174hz 11%</p>
      <p>285hz 8%</p>
      <p>396hz 12%</p>
      <p>417hz 14%</p>
      <p>432hz 9%</p>
      <p>528hz 15%</p>
      <p>639hz 10%</p>
      <p>852hz 6%</p>
      <p>963hz 10%</p>
      <p>The exact numbering of the different elements of this feature and their description is given below.</p>
      <p>The sounds from space (10), along with the frequencies (10) together form 100 individual sounds. The algorithm
        ensures that one sound is used certain number of times in the collection, so that a given percentage of the
        collection have the sound of a particular planet or star at a particular frequency.</p>
      <img src="../../../assets/aliens-frequency.png" alt="buzzle-aliens">
    </section>
    <section class="aliens__article">
      <h1>10. SOUND PLAY SPEED</h1>
      <p>There are 3 audio playback speeds in the collection. Among them are: 1x, 2x and 3x.</p>
      <p>This trait occurs variously in the collection and is dependent on the intensity of the original sound of the
        planet or star in combination with the artist’s intended purpose.</p>
      <p>1x 60%<br>2x 24%<br>3x 16%</p>
      <p>The exact numbering of each element of this feature is listed below.</p>
      <img src="../../../assets/aliens-sound-play-speed.png" alt="buzzle-aliens"
           style="margin-bottom: 100px; margin-top:20px;">
      <p>NOTE! During future DROPS, the percentage of specific traits in the entire collection on OpenSea will change-
        the aLIEnS for specific DROPS are randomly selected from 1,000 ready-made Collectibles. Once all 1,000 aLIEnS
        are available on OpenSea, the specific percentage of traits in the entire collection will match the one
        presented above.</p>
      <p>In addition, Opensea takes time to refresh so that all features show up correctly in the filter bar.</p>
      <p>All available aLIEnS can be viewed
        <router-link to="/collectibles/aliens/all">here</router-link>
        .
      </p>
    </section>
    <section class="aliens__article" id="drops">
      <h1>DROPS & PRICING</h1>
      <p>The floor price starts at 0.01E and increases with each DROP. The next DROP takes place after the primary sale
        of the previous DROP has ended. Prices for subsequent DROPS will be posted in real time before the DROP.</p>
      <p>The entire collection is divided into 10 DROPS. There are 100 collectible pieces in each DROP. Each DROP is
        also 10% of the sales of the entire collection. As the community grows and the number of Collectors who own
        aLIEnS increases, further Roadmap goals will be successfully achieved – according to the % of sales of the
        entire collection. Each goal will be initiated after particular DROP sold out.</p>
      <p>You can see Roadmap 1.0
        <router-link to="/collectibles/aliens">here</router-link>
        .
      </p>
      <p>The price of the first DROP will be the lowest and will increase as follows:</p>
      <p>1-10 = 0.01 ETH</p>
      <p>11-20 = 0.02 ETH</p>
      <p>21-30 = 0.03 ETH</p>
      <p>31-40 = 0.04 ETH</p>
      <p>41-50 = 0.05 ETH</p>
      <p>51-60 = 0.06 ETH</p>
      <p>61-70 = 0.07 ETH</p>
      <p>71-80 = 0.08 ETH</p>
      <p>81-90 = 0.09 ETH</p>
      <p>91-100 = 0.1 ETH</p>
      <p>Prices for the following DROPS will increase continuously. All DROPS except the first have a fixed price per
        DROP. The prices of the first DROP will increase gradually in the range of one DROP.</p>
      <p>After the original sale ends, aLIEnS can be purchased from someone on the secondary market. Everything is done
        on the blockchain. All users can make offers, buy and sell aLIEnS. </p>
      <p>What happens after Roadmap 1.0 is complete? See <a @click="scrollToEL('roadmap')">here</a>.</p>
    </section>
    
    <section class="aliens__article" id="technical">
      <h1>Technical stuff</h1>
      <p>aLIEnS are handcrafted Collectibles stored on decentralized „Ethereum’s Internet of Blockchains”- Polygon. Read
        more about Polygon <a href="https://polygon.technology/" target="_blank">here</a>.</p>
      <p>The reason is to reduce the costs associated with buying and selling artworks (Ethereum gas fees). Polygon
        brings the best of where Ethereum has taken us, but improves certain features and makes it easier for the
        community to access the artist’s works and the club.</p>
      <p>As a result, buying and selling is without gas fees. </p>
      <p>In addition to easier access and fewer fees for the community, Polygon is a very developmental environment with
        which the artist has further plans for future Roadmaps (Metaverse stuff and Amusement Arcade Platform).</p>
      <p>Of course, the Polygon environment works closely with the OG Ethereum blockchain-all artwork is
        Ethereum-compatible.</p>
      <p>Polygon as well as Ethereum allows you to produce any code on the blockchain, and the results of the execution
        are stored forever.</p>
      <p>Once published on the blockchain, the collection pieces are there permanently and cannot be modified in any
        way. Among other things, this is the reason for their uniqueness and the certainty that only 1,000 pieces
        exist.</p>
      <p>Each piece of artwork, after being hand-crafted, with the cooperation of an algorithm that helps determine
        specific percentages of features in the collection, is placed on Polygon.</p>
      <p>During this process, the name, traits, original MP4 file, description, and JPEG file as cover are uploaded. As
        additional content, each NFT contains the original source file (MP4) and a cover for use in social media
        (JPEG)-all additional files are stored on a decentralized server using IPFS. The buyer will receive a link to
        download the assets from the decentralized storage.</p>
      <p>Then, once the NFT is created, its Metadata is frozen. Metadata is all the information about the NFT entered
        during its creation. Freezing is the process that permanently „freezes” data on decentralized storage. This is
        why once an NFT is created and frozen, it is 100% stored on the decentralized network and the artist cannot
        change anything about its contents or data.</p>
    </section>
    <section class="aliens__article aliens__article--faq" id="faq">
      <h1>FAQ</h1>
      <div class="item">
        <h2>
          Total aLIEnS
        </h2>
        <p>1,000</p>
      </div>
      <div class="item">
        <h2>How to get your own aLIEn?</h2>
        <p>1. Download and install a Chrome browser plugin MetaMask. Doing so will allow authorized websites to access
          your Ethereum account. Instructions on how to install MetaMask can be found <a href="https://metamask.io/faqs"
                                                                                         target="_blank">here</a>.</p>
        <p>2. Once you have created an account, buy the desired amount of ETH. Instructions on how to buy ETH through
          MetaMask can be found <a href="https://metamask.io/faqs" target="_blank">here</a>.</p>
        <p>3. Generally, its good to go. However, all the artist’s works are stored on „Ethereum’s Internet of
          Blockchains”- Polygon. Read more about Polygon <a href="https://polygon.technology/" target="_blank">here</a>.
        </p>
        <p>Above you can read about what benefits Polygon brings.</p>
        <p>As a result, buying and selling is without gas fees. However, to be able to buy works on the Polygon
          blockchain, you need to click in one place in your wallet on OpenSea. Check this out <a
              href="https://support.opensea.io/hc/en-us/articles/1500012881642-How-do-I-transfer-ETH-from-Ethereum-to-Polygon-">here</a>.
        </p>
        <p>4. After installing the plugin, specific sites will recognize it. This allows you to bid, auction, buy and
          sell collectible art on the marketplace. For example, you can buy aLIEn <a
              href="https://opensea.io/collection/1000aliens" target="_blank">here</a>.</p>
      </div>
      <div class="item">
        <h2>Release date</h2>
        <p>Drop release date will be revealed via <a
            href="https://discord.com/channels/880564549181399081/891444749683273749" target="_blank">Discord</a>
          (@announcements)</p>
        <p>Avoid FOMO and <a href="https://discord.com/channels/880564549181399081/880564549181399083" target="_blank">join</a>
          community!</p>
      </div>
      <div class="item">
        <h2>Where to get artpieces?</h2>
        <p>All artworks and collectibles will be available to purchase in public sale on OpenSea.</p>
        <p>However, there is an opportunity to make offers before the public sale begins. Actually, offers can be made
          at any time. The current owner can accept it or not. See below what is the difference between buying from
          primary and secondary market.</p>
      </div>
      <div class="item">
        <h2>Which blockchain is used by artist?</h2>
        <p>All of the artist’s artworks are on the Polygon Blockchain. Read more about Polygon <a
            href="https://polygon.technology/" target="_blank">here</a>.</p>

      </div>
      <div class="item">
        <h2>Why Polygon?</h2>
        <p>The reason is to reduce the costs associated with buying and selling artworks (Ethereum gas fees). Polygon
          brings the best of where Ethereum has taken us, but improves certain features and makes it easier for the
          community to access the artist’s works and the club.</p>
        <p>As a result, buying and selling is without gas fees. In addition to easier access and fewer fees for the
          community, Polygon is a very developmental environment with which the artist has further plans for future
          Roadmaps (Metaverse stuff and Amusement Arcade Platform).</p>
        <p>Of course, the Polygon environment works closely with the OG Ethereum blockchain-all artwork is
          Ethereum-compatible.</p>
        <p>Polygon as well as Ethereum allows you to produce any code on the blockchain, and the results of the
          execution are stored forever.</p>
      </div>
      <div class="item">
        <h2>What is the pricing structure?</h2>
        <p>Each collection has a pricing strategy related to the number of artworks in the collection and their
          uniqueness. It is set for the highest return on investment for Collectors. Early investors are especially
          rewarded – the price increases with each DROP.</p>
      </div>
      <div class="item">
        <h2>Why are prices going up?</h2>
        <p>Just because. JK.</p>
        <p>The prices in each collection increase with the DROP. The reason is that with each DROP the number of
          available works on the primary market decreases. See further what the difference is between primary and
          secondary purchase.</p>
        <p>Another reason is that, collectors who join the club earlier, have more benefits. Faith in the artist and his
          vision is rewarded. Everyone wants to be in the project as it is popular, but true Secret Society Club members
          are in the club not only for the possible financial benefits, but because they believe in the project from the
          beginning.</p>
        <p>Additional reasons relate to liquidity and distribution consistent with the spirit of the club and the
          artist’s vision.</p>
      </div>
      <div class="item">
        <h2>Roadmap?</h2>
        <p>aLIEnS and each future Collectible series has its own Roadmap. See
          <router-link to="/collectibles/aliens">Roadmap 1.0</router-link>
          . Priorities here are community
          related activities and targets.
        </p>
        <p>Every Roadmap ends with Breeding. New aLIEnS from another dimension arrive. A new series of Collectibles is
          initiated.</p>
        <p>A New Collectibles series starts a new Roadmap with new activities and community-focus targets.
          See Roadmap 2.0 sneak peak
          <router-link to="/collectibles/metaliens">here</router-link>
          .
        </p>
        <p>The artist wants to give as much as possible to the community. Each phase of the Roadmap as the project
          develops will be refined with the best interests of the community and club members in mind.</p>
      </div>
      <div class="item">
        <h2>Artist's long-term vision?</h2>
        <p>The aLIEnS and Chapter 1.0 collections along with the Roadmap 1.0 assumptions are just the beginning.</p>
        <p>
          <router-link to="/collectibles/metaliens">Here's</router-link>
          a sneak peek of future Roadmap 2.0.
        </p>
        <p>In addition to the Collectibles series from Roadmap, the artist simply wants to create more artwork and tell
          a story according to his own vision.</p>
        <p>There will also be special collections unrelated to the Collectibles and Chapter series. Everything is a
          secret at this point and will be revealed to the community in proper time.</p>
        <p>The artist has always been involved with art and graphic design. Thanks to NFT he can do what he loves and
          what he was created for – there is no way back.</p>
      </div>
      <div class="item">
        <h2>What is the difference between purchasing an alien from the primary market vs purchasing one from the
          secondary?</h2>
        <p>Having aLIEn makes you an honorary member of Secret Society Club. You have access to all the activities that
          Roadmap offers. Why honorary? Future Collectibles will also entitle you to Secret Society Club, but the first
          collection is only one – aLIEnS owners will have special benefits of owning aLIEn from the OG collection in
          the future. It doesn’t matter if you buy aLIEn from primary or secondary sale- you are still a current member
          of the club.</p>
        <p>So what’s the deal with buying from the primary market? Well, the artist wanted to give back to the community
          and set a reward of 20 ETH for one randomly chosen adress. The raffle will include addresses that were the
          original and first owner of each aLIEn.</p>
        <p>The raffle will take place before Breeding, and therefore at the end of Roadmap 1.0.</p>
      </div>
      <div class="item">
        <h2>How to become a Secret Society Club member?</h2>
        <p>If you own aLIEn, you are a member of the club.</p>
      </div>
      <div class="item">
        <h2>How to take part in the 20 ETH raffle?</h2>
        <p>Addresses that first own each of the 1,000 aLIEns, and therefore purchase from primary market, will
          participate in the 20 ETH raffle after Roadmap 1.0.</p>
      </div>
      <div class="item">
        <h2>What about the giveaways?</h2>
        <p>In addition to the 20 ETH raffle, there will be a total of 10 ETH in 10 giveaways on Discord.</p>
        <p>2 giveaways each time a Phase is completed.</p>
        <p>As below:</p>
        <p>Phase 1 completed</p>
        <p>2x 1 ETH</p>
        <p>Phase 2 completed</p>
        <p>2x 1 ETH</p>
        <p>Phase 3 completed</p>
        <p>2x 1 ETH</p>
        <p>Phase 4 completed</p>
        <p>2x 1 ETH</p>
        <p>Phase 5 completed</p>
        <p>2x 1 ETH</p>
        <p>You can find the details of how to participate in the giveaways on <a
            href="https://discord.com/channels/880564549181399081/895982480648183818" target="_blank">Discord</a>
          (@giveaways)</p>
        <p>In addition to the ETH giveaway, upon completion of Phase 2, the existing 10 club members will be awarded 1
          randomly selected aLIEn.</p>
      </div>
      <div class="item">
        <h2>What makes the project unique?</h2>
        <p>Just everything.</p>
        <p>Here are some highlights/</p>
        <p>/Secret Society Club</p>
        <p>/Retro vibes</p>
        <p>/Arcade Games</p>
        <p>/No gas fees</p>
        <p>/Community-focus</p>
        <p>/Total of over 30 ETH + 10 aLIEnS in giveaways in Roadmap 1.0 only</p>
        <p>/Breeding with the opportunity for club members to pick up 1 random Collectible from the new series</p>
        <p>/Metaverse stuff</p>
        <p>/Merch</p>
        <p>/Handcrafted</p>
        <p>/Floor in 1st DROP is 0.01 ETH</p>
        <p>/Medium price among 1,000 aLIEnS on primary market aimed to be around 0.5 ETH</p>
        <p>/Charity</p>
        <p>/
          <router-link to="/collectibles/metaliens">Roadmap 2.0</router-link>
          sneak peak
        </p>

        <p>Join <a href="https://discord.com/channels/880564549181399081/880564549181399083" target="_blank">Discord</a>
          so you don’t miss out.</p>
      </div>
      <div class="item">
        <h2>Who is BUZZLE?</h2>
        <p>Check it out
          <router-link to="/about">here</router-link>
          .
        </p>
      </div>
      <div class="item">
        <h2>Pre-Sale?</h2>
        <p>No.</p>
      </div>
      <div class="item">
        <h2>White List?</h2>
        <p>Check it out
          <router-link to="/whitelist">here</router-link>
          .
        </p>
      </div>
      <div class="item">
        <h2>Owners Raffle?</h2>
        <p>No.</p>
      </div>
      <div class="item">
        <h2>Gas fees?</h2>
        <p>NO GAS FEES</p>
        <p>0%</p>
      </div>
      <div class="item">
        <h2>What will be the initial price of aLIEn?</h2>
        <p>0.01 ETH</p>
      </div>
      <div class="item">
        <h2>What will be the medium price of aLien for the whole collection?</h2>
        <p>Aimed to be around 0.5 ETH</p>
      </div>
    </section>
  </section>
</template>

<style lang="scss">
.roadmap-image {
  width: 100%;
  margin: 20px 0;

  img {
    width: 100%;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

.button-bounce {
  //animation-name: bounce;
  //animation-duration: 2s;
  //animation-iteration-count: infinite;
}

.aliens {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  &__article {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    margin-bottom: 150px;

    &--faq {
      .item {
        padding: 25px 0;

        h2 {
          color: #ff006a;;
          font-family: 'Press Start 2P', cursive;
        }
      }
    }

    .phase {
      margin: 100px 0;

      &__title {
        margin-bottom: 60px;

        p:nth-child(1) {
          text-decoration: underline;
          font-weight: bold;
        }
      }

      p {
        margin: 10px 0;
      }
    }

    h1 {
      font-family: 'Press Start 2P', cursive;
      font-size: 1.8rem;
      padding-top: 20px;
      margin: 0;
    }

    p {
      margin: 40px 0;
      font-size: 19px;

      a {
        color: #c36;
        text-decoration: none;
      }
    }

    img {
      padding: 15px;
      @media screen and(min-width: 992px) {
        padding: 0 100px;
      }
      max-width: 100%;
    }
  }

  &__buttons {
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and(max-width: 992px) {
      //flex-direction: column;
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
      button {
        margin: 10px;
        //width: 200px;
        padding: 15px !important;
        font-size: .85rem !important;
      }
    }

    a {
      button {
        padding: 15px 35px;
        font-family: 'Josefin Sans', sans-serif;
        border-radius: 5px;
        font-size: 1.2rem;
        border: 2px solid white;
        color: black;
        background: white;

        &:hover {
          cursor: pointer;
        }

      }

      &:nth-child(odd) {
        button {
          background-color: #ff006a;
          color: white;
        }
      }
    }
  }
}
</style>

<script>
export default {
  methods: {
    scrollToEL(id) {
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({behavior: 'smooth'});
    }
  },
  mounted() {
    document.title = this.page_title + ' - aLIEnS'
  }
}
</script>