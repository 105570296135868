<template>
  <section class="collections">
    <div class="collections__heading">
      <div class="title">
        <h1>Collectibles.</h1>
      </div>
      <div class="suggestion">
        <h5>
          See first:
          <router-link to="/to-collectors">to Collectors</router-link>
          .
        </h5>
      </div>
    </div>
    <div v-if="loading" class="collections__error">
      Collectibles. Invading...
    </div>
    <div v-if="collections">
      <div class="collections__item" v-for="collection in collections" :key="collection.id"
           v-bind:style="{ backgroundImage: collection.photo ? 'url(' + collection.photo.formats.large.url + ')' : ''}">
        <div class="box">
          <h1>{{ collection.name }}</h1>
          <h5>{{ collection.description }}</h5>
          <h5>Pieces: {{ collection.pieces }}</h5>
          <h5>{{ collection.pieces_per_drop }} piece per drop</h5>
          <h5 v-if="collection.release_date !== 'SEE HERE'">Release date: {{ collection.release_date }}</h5>
          <h5 v-else-if="collection.slug">Release date: SEE
            <router-link :to="'/collectibles/'+collection.slug">HERE</router-link>
          </h5>
          <router-link :to="'/collectibles/'+collection.slug" v-if="collection.is_active">
            <button>
              GO TO COLLECTION
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="collections__error">
      {{ error }}
    </div>
  </section>
</template>
<style lang="scss" scoped>
.loading {
  animation: rotation 1s infinite;
  width: 5px;
  height: 5px;
  @keyframes rotation {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
  }
}

.collections {
  width: 100%;

  &__heading {
    width: 1280px;
    max-width: 100%;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    margin: 80px auto;

    h1 {
      text-transform: uppercase;
      font-size: 40px;
      @media screen and(max-width: 468px) {
        font-size: 1.6rem;
      }
    }

    h5 {
      font-size: 1.6rem;
      @media screen and(max-width: 468px) {
        font-size: 1.3rem;
      }

      a {
        color: #c36;
        text-decoration: none;

      }
    }


  }

  &__item {
    background-color: #333;
    border: 5px solid white;
    margin: 50px 0;
    background-size: cover;
    background-position: center;

    .box {
      background: black;
      border: 5px solid white;
      max-width: 500px;
      margin: 100px auto;
      text-align: center;
      padding: 30px;
      text-transform: uppercase;

      h1 {
        font-family: 'Press Start 2P', cursive;
        font-size: 2rem;
        color: #cc3366;
        text-transform: none;
        @media screen and(max-width: 468px) {
          font-size: 1.3rem;
        }
      }

      h5 {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 1rem;
        @media screen and(max-width: 468px) {
          font-size: .9rem;
        }

        a {
          color: #cc3366;
          text-decoration: none;
        }
      }

      button {
        font-family: 'Press Start 2P', cursive;
        background: black;
        border: 5px solid white;
        color: white;
        padding: 10px 30px;

        &:hover {
          cursor: pointer;
          background: #c36;
        }
      }
    }

  }

  @keyframes bounce {
    0% {
      transform: scale(1)
    }
    50% {
      transform: scale(1.05)
    }
    100% {
      transform: scale(1)
    }
  }

  &__error {
    font-family: 'Press Start 2P', cursive;
    color: #ff006a;
    font-size: 2rem;
    text-align: center;
    animation: bounce 1s infinite;
    overflow: hidden;
    max-width: 1024px;
    width: 100%;
    margin: 50px auto;
  }
}
</style>
<script>
import axios from 'axios';

let api_url = process.env.VUE_APP_API_URL

export default {
  data: function () {
    return {
      collections: false,
      loading: true,
      error: '',
      api_url: api_url
    }
  },
  methods: {
    formatDate(dateString) {
      let d = new Date(0) // The 0 there is the key, which sets the date to the epoch
      d.setUTCMilliseconds(dateString)
      return d.getMonth() + '-' + d.getDay() + '-' + d.getFullYear()
    }
  },
  async mounted() {
    document.title = this.page_title + ' - Collectibles'
    try {
      await axios.get(api_url + '/collections?type=collectible').then(r => {
        this.collections = r.data
      })
    } catch (error) {
      this.collections = false
      this.error = 'Our servers are currently down. Please try again later.'
    }
    this.loading = false
  }
}
</script>