<template>
  <footer>
    <!--    <div class="polygon-studios">-->
    <!--      <h4>Powered by</h4>-->
    <!--      <a href="https://polygon.technology/" target="_blank">-->
    <!--        <img src="../../assets/polygon.png" alt="polygon-studios">-->
    <!--      </a>-->
    <!--    </div>-->
    <div class="footer">
      <div class="footer__col">
        <h5>Community Focus Stuff</h5>
        <a v-for="icon in c_f_s" :key="icon.link" :href="icon.link" target="_blank">
          <i :class="icon.icon"></i>
        </a>
      </div>
      <div class="footer__col partners">
        <h5>Partners</h5>
        <div class="box">
          <div class="box__item">
            <a href="https://polygon.technology" target="_blank">
              <img src="../../assets/polygon.png" alt="polygon">
            </a>
          </div>
          <div class="box__item">
            <a href="https://polygonstudios.com/" target="_blank">
              <img src="../../assets/polygon.gif" alt="polygon-studios">
            </a>
          </div>
          <div class="box__item">
            <a href="https://opensea.io/" target="_blank">
              <img src="../../assets/opensea.png" alt="opensea">
            </a>
          </div>
          <div class="box__item">
            <a href="https://saihokonft.com/" target="_blank">
              <img src="../../assets/saihokonft.png" alt="saihokonft">
            </a>
          </div>
          <div class="box__item">
            <a href="https://moralis.io/" target="_blank">
              <img src="../../assets/moralis.png" alt="saihokonft">
            </a>
          </div>
          <div class="box__item">
            <a href="https://codeclutch.dev/" target="_blank">
              <img src="../../assets/codeclutch.png" alt="codeclutch">
            </a>
          </div>
        </div>
      </div>
      <div class="footer__col">
        <h5>Links to Collection on OpenSea</h5>
        <div class="buttons">
          <a target="_blank" href="https://opensea.io/collection/1000aliens">
            <button>aLIEnS</button>
          </a>
          <!--          <a target="_blank" href="https://opensea.io/collection/chapter-1-buzzle">-->
          <!--            <button>Chapter 1.0</button>-->
          <!--          </a>-->
        </div>
        <div class="link">
          ||
          <router-link to="/">www.buzzle-art.com</router-link>
          ||
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.polygon-studios {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
  padding-top: 20px;

  img {
    padding-left: 10px;
    max-width: 100px;
  }
}

.footer {
  max-width: 100%;
  width: 100%;
  padding: 40px 20px 20px;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  font-family: 'Josefin Sans', sans-serif;
  @media screen and(max-width: 992px) {
    flex-direction: column;
  }

  .partners {
    .box {
      margin: 0 50px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;

      &__item {
        display: flex;
        padding: 10px;
        align-items: center;

        img {
          width: 100%;
        }
      }
    }
  }

  &__col {
    width: 33%;
    @media screen and(max-width: 992px) {
      width: 100%;
    }
    text-align: center;


  }

  h5 {
    text-transform: uppercase;
  }

  a {
    color: white;

    i {
      font-size: 1.6rem;
      padding: 10px 20px;

      &:hover {
        color: #c36;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin: 10px 0;
      max-width: 150px;
      font-family: 'Press Start 2P', cursive;
      background: white;
      border-radius: 5px;
      outline: none;
      padding: 10px;
      font-size: .5rem;

      &:hover {
        cursor: pointer;
        background: #c36;
      }
    }
  }

  .link {
    margin-top: 20px;

    a {
      text-decoration: none;
      color: #c36;

      &:hover {
        color: #336;
      }
    }
  }
}
</style>
<script>
export default {
  data: function () {
    return {
      c_f_s: [{ //Community Focus Stuff
        icon: 'fab fa-twitter',
        link: 'https://twitter.com/buzzle_art'
      }, {
        icon: 'fab fa-discord',
        link: 'https://discord.com/invite/BreZcEMr7q'
      },
        {
          icon: 'fab fa-instagram',
          link: 'https://www.instagram.com/buzzle.art/'
        }],
      o_s_m_s: [{ //Other Social Media Stuff
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/buzzle.art/',
      }, {
        icon: 'fab fa-tiktok',
        link: 'https://www.tiktok.com/@buzzle.art',
      }, {
        icon: 'fab fa-reddit',
        link: 'https://www.reddit.com/user/buzzle_art',
      }, {
        icon: 'fab fa-facebook',
        link: 'https://www.facebook.com/buzzle.art',
      }, {
        icon: 'fab fa-linkedin',
        link: 'https://www.linkedin.com/in/b-u-z-z-l-%CE%BE-a593b221b/',
      }, {
        icon: 'fab fa-pinterest',
        link: 'https://pl.pinterest.com/buzzle_art/_saved/',
      }]
    }
  }
}
</script>