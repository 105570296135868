<template>
  <section class="members">
    <h1>Members</h1>
    <h3>Meet members of our Secret Society Club</h3>
    <div class="container">
      <div class="grid">
        <div class="member" v-for="member in members" v-bind:key="member.name">
          <div class="image"
               :style="'background-image: url('+member.normal_photo+')'">
            <img class="image-alien" :src="member.alien_photo" :alt="member.name">
          </div>
          <p>aLIEn #{{ member.alien_number }}</p>
          <p class="alien-number">{{ member.name }}</p>
          <p>
            <a :href="member.insta" target="_blank">
              <i class="fab fa-instagram"></i> {{ member.follow }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <h2>Stay tuned!</h2>
    <h3>The Club will grow through all the time.</h3>
  </section>
</template>
<script>
export default {
  data: function () {
    return {
      members: [
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/gf_Gep_Z_a_Wg_T_Eayo_antoni_krolikowski_664x442_nocrop_ecee2b24a2.jpg',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/small_CRYPTO_RABBIT_807c2fec3e.png',
          name: '@antek.krolikowski',
          follow: '491k',
          alien_number: '105',
          insta: 'https://www.instagram.com/antek.krolikowski/?hl=pl',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/hr_Xktkp_TURB_Xy9i_Mz_E0_ZWE_2_N2_Q5_N2_Ez_ZT_Ay_Yz_E1_NWY_4_Mj_Fk_OW_Iw_N_Dhm_MS_5qc_Ge_Sl_QM_Az_Kv_NFWDND_Aa_T_Bc0_Bp_M0_BLA_38266592b2.jfif',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_113_2d96807d5b.png',
          name: '@liroy_marzec',
          follow: '11.4k',
          alien_number: '113',
          insta: 'https://www.instagram.com/liroy_marzec/',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/Joanna_Opozda_padla_ofiara_sutenerow_Moj_strach_i_placz_na_nikim_nie_robily_wrazenia_FOTO_article_491d70ba1c.jpg',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_116_6f5c412b12.jpg',
          name: '@asiaopozda',
          follow: '438k',
          alien_number: '116',
          insta: 'https://www.instagram.com/asiaopozda/?hl=pl',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_118_d089b3ca5f.jpg',
          name: '@maffashion_official',
          follow: '1.4 M',
          alien_number: '118',
          insta: 'https://www.instagram.com/maffashion_official/?hl=pl',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_121_0cb5e2a9b3.jpg',
          name: '@michalkarmowski',
          follow: '221k',
          alien_number: '121',
          insta: 'https://www.instagram.com/michalkarmowski/',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_125_cf7bdc8f72.jpg',
          name: '@piotrkedzierski',
          follow: '80.2k',
          alien_number: '125',
          insta: 'https://www.instagram.com/piotrkedzierski/',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_130_53a3d8d277.png',
          name: 'TBA',
          follow: 'TBA',
          alien_number: '130',
          insta: '',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_119_f27a5b298e.jpg',
          name: 'TBA',
          follow: 'TBA',
          alien_number: '119',
          insta: '',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_333_344bfb4946.jpg',
          name: 'TBA',
          follow: 'TBA',
          alien_number: '333',
          insta: '',
        }, {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_120_7a46c2d1ff.jpg',
          name: 'TBA',
          follow: 'TBA',
          alien_number: '120',
          insta: '',
        },

      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.members {
  font-family: 'Josefin Sans', sans-serif;

  h1, h3, h2 {
    text-align: center;
    margin: 15px;
  }

  h1 {
    margin-top: 40px;
    font-size: 3em;
  }


  .grid {
    font-family: 'Josefin Sans', sans-serif;
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    padding-top: 30px;
    grid-template-columns: repeat(4, 1fr);
    @media screen and(max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
    grid-gap: 15px;

    &--sm {
      margin-top: 15px;
      grid-template-columns: repeat(8, 1fr);
    }

    .member {
      p {
        font-size: 1.4rem;
        margin: 10px 0;
        font-weight: bold;
        text-align: center;

        a {
          color: white;
          text-decoration: none;

          i {
            color: white;
            padding: 0 5px;
          }
        }
      }

      .alien-number {
        font-size: .9em;
        color: #ff006a;
      }

      .image {
        display: flex;
        background-size: cover;
        background-position: center;
      }

      .image-normal {
        display: none;
      }


      @keyframes fadeIn {
        from {
          top: -320px;
        }
        to {
          top: 0;
        }
      }

      @keyframes fadeOut {
        from {
          top: 0;
        }
        to {
          top: -320px;
        }
      }

      .image {
        //border: 2px solid white;
        width: 100%;
        //height: 300px;
        overflow: hidden;
        background-size: cover;
        background-position: top;

        .image-alien {
          opacity: 1;
          transition: 1s;
        }

        &:hover {
          .image-alien {
            opacity: 0;
          }
        }

        img {
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>