<template>
  <section class="alien-page">
    <div class="alien-page__heading">
      <div class="video">
        <video v-if="alien.video" autoplay controls muted playsinline>
          <source :src="alien.video.url" type="video/mp4">
        </video>
      </div>
      <div class="button">
        <a :href="alien.open_sea_link" target="_blank">
          <button>
            SEE ON OPENSEA
          </button>
        </a>
      </div>
      <div class="name">
        <h1>alien {{ alien.number }}</h1>
        <p>Individual code</p>
        <p>{{ alien.individual_code }}</p>
      </div>
    </div>
    <div class="alien-page__traits">
      <h1>Traits</h1>
      <div class="wrapper">
        <div class="item" v-for="trait in alien.traits" v-bind:key="trait.name">
          <h2>{{ trait.name }}</h2>
          <h3>{{ trait.value }}</h3>
        </div>
      </div>
      <router-link to="/collectibles/aliens/all">
        <button>
          See description of all traits
        </button>
      </router-link>
    </div>
    <div class="alien-page__info">
      <div class="item" v-for="info in alien.info" v-bind:key="info.name">
        <h1>{{ info.name }}</h1>
        <p>{{ info.value }}</p>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.alien-page {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  &__info {
    padding: 0 10px;
    @media screen and (min-width: 992px) {
      padding: 0 50px;
    }

    .item {
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @media screen and(max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
      text-align: center;
      font-family: 'Press Start 2P', cursive;
      @media screen and (min-width: 992px) {
        padding: 30px 0;
      }

      h1 {
        color: #ff006a;
        font-size: 1rem;
        width: 100%;
      }

      p {
        max-width: 320px;
        word-break: break-all;
        @media screen and(max-width: 992px) {
          font-size: .8rem;
        }
      }
    }
  }

  &__traits {
    display: flex;
    flex-direction: column;
    align-items: center;

    .wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      @media screen and(max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media screen and(max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and(max-width: 512px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media screen and(max-width: 380px) {
      }
    }


    h1 {
      font-family: 'Press Start 2P', cursive;
      font-size: 2rem;
      color: white;
      text-align: center;
    }

    .item {
      font-family: 'Press Start 2P', cursive;
      text-align: center;

      h2 {
        color: white;
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      h3 {
        color: #ff006a;
        font-size: 1.2rem;
        text-transform: uppercase;
      }
    }

    button {
      font-family: 'Press Start 2P', cursive;
      background: #ff006a;
      border-radius: 5px;
      border: 2px solid white;
      color: white;
      font-size: 1.2rem;
      padding: 20px 40px;
      margin: 40px 0;
      text-transform: uppercase;

      &:hover {
        background: #cc3366;
        cursor: pointer;
      }
    }

  }

  &__heading {
    margin: 50px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media screen and(max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .video {
      width: 100%;

      video {
        max-width: 100%;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      h1 {
        color: #ff006a;
        font-family: 'Press Start 2P', cursive;
        font-size: 2rem;
        margin: 0;
        padding: 0;
      }

      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-family: 'Press Start 2P', cursive;
        font-size: .8rem;

      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;

      button {
        font-family: 'Press Start 2P', cursive;
        background: #ff006a;
        border-radius: 5px;
        border: 2px solid white;
        color: white;
        font-size: 1.4rem;
        padding: 10px 30px;

        &:hover {
          background: #cc3366;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<script>
import axios from 'axios'

let api_url = process.env.VUE_APP_API_URL

export default {
  data: function () {
    return {
      alien_id: this.$route.params.id,
      api_url: api_url,
      alien: {
        video: null,
        open_sea_link: null,
        number: null,
        individual_code: null,
        traits: [],
        info: [],
      }
    }
  },
  async mounted() {
    try {
      await axios.get(api_url + '/aliens?number_id=' + this.alien_id).then(r => {
            r.data = r.data[0]
            this.alien.video = r.data.video
            this.alien.open_sea_link = r.data.open_sea_link
            this.alien.number = r.data.number
            this.alien.individual_code = r.data.individual_code
            // Fill traits //
            this.alien.traits.push({name: 'background', value: r.data.traits_background})
            this.alien.traits.push({name: 'texture', value: r.data.traits_texture})
            this.alien.traits.push({name: 'body', value: r.data.traits_body})
            this.alien.traits.push({name: 'eyes', value: r.data.traits_eyes})
            this.alien.traits.push({name: 'nose', value: r.data.traits_nose})
            this.alien.traits.push({name: 'mouth', value: r.data.traits_mouth})
            this.alien.traits.push({name: 'color', value: r.data.traits_color})
            this.alien.traits.push({name: 'sounds', value: r.data.traits_sounds})
            this.alien.traits.push({name: 'frequency', value: r.data.traits_frequency})
            this.alien.traits.push({name: 'sound play speed', value: r.data.traits_play_speed})
            // Fill info //
            this.alien.info.push({name: 'Contact address', value: r.data.contact_address})
            this.alien.info.push({name: 'Token ID', value: r.data.token_id})
            this.alien.info.push({name: 'Token Standard', value: r.data.token_standard})
            this.alien.info.push({name: 'Blockchain', value: r.data.blockchain})
            this.alien.info.push({name: 'Metadata', value: r.data.metadata})


            document.title = this.page_title + ' - aLIEn ' + this.alien.number

          }
      );
    } catch (e) {
      await this.$router.push('/');
    }
  }
}
</script>