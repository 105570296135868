<template>
  <section class="chapter">
    <div class="chapter__wrapper">
      <h2>Chapter 1.0</h2>
      <div class="chapter__article">
        <p>Chapter 1.0 is BUZZLE’s first collection of unique 1/1 artworks.</p>
        <p>&nbsp;</p>
        <p>The collection opens a series of future collections (Chapter 2.0, Chapter 3.0…) by the artist, in which
          each artwork within a Chapter tells a different story and prompts reflection.</p>
        <p>&nbsp;</p>
        <p>More stories will be told in subsequent artworks (subsequent DROPS) within one collection.</p>
        <p>&nbsp;</p>
        <p>Each Chapter collection consists of 10 unique 1/1 artworks.</p>
        <p>&nbsp;</p>
        <p>Every piece of art is a different message and a short mind-opening story.</p>
        <p>&nbsp;</p>
        <p>Each artwork from „Chapter” collections will automatically be an honorary membership to Buzzle Art Club.
        </p>
        <p>&nbsp;</p>
        <p>Generally, the next work in the collection is released after the previous piece sold out. However, the
          artist reserves the right to publish new works of art on an ongoing basis as part of the collection
          series „Chapter”.</p>
      </div>
      <div class="chapter__media">
        <div class="row row--mobile">
          <div class="video">
            <video width="100%" controls autoplay loop muted>
              <source src="../../../assets/chapter-ufo.mp4" type="video/mp4">
            </video>
          </div>
          <div class="text">
            <h2>Capitol UFO</h2>
            <p>United States Capitol, 1952.</p>
            <p>&nbsp;</p>
            <p>UFOs don’t get involved in earthly affairs on a day-to-day basis. People are amusing creatures who
              don’t threaten UFOs or life in the universe.</p>
            <p>&nbsp;</p>
            <p>However, they are fed up with the nuclear mood prevailing on earth.</p>
            <p>&nbsp;</p>
            <p>Nuclear weapons pose a real threat to our planet. UFOs realize that if earth is significantly
              destroyed, it will affect the entire structure of our galaxy.</p>
            <p>&nbsp;</p>
            <p>They can’t let that happen. UFOs decide to shake their finger at humanity and show a sample of their
              power. The message is clear:</p>
            <p>„You think the world is yours, but we are watching”.</p>
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-2-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-3-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-4-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-5-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-6-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-7-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-8-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-9-820x1024.png" alt="">
          </div>
        </div>
        <div class="row">
          <div class="description">
            <div class="description__column">
              <h2>TBA</h2>
              <p>TBA</p>
            </div>
          </div>
          <div class="photo">
            <img src="../../../assets/DROP-10-820x1024.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
.chapter {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  font-family: 'Josefin Sans', sans-serif;

  &__wrapper {
    margin: 30px;

    h2 {
      font-size: 2.4rem;
      text-align: center;
    }

  }

  &__article {
    width: 1024px;
    margin: 0 auto;
    max-width: 100%;

    p {
      font-size: 19px;
      text-align: center;
    }
  }

  &__media {
    margin-top: 100px;

    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      margin: 40px 0;

      &--mobile {
        @media screen and(max-width: 1024px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &:nth-child(odd) {
        .description {
          order: 2;
        }
      }

      .text {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 50px;
        p{
          margin: 0;
        }
      }

      .description {
        display: flex;
        align-items: center;
        justify-content: center;


        &__column {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .photo {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

<script>
export default {}
</script>