<template>
  <section class="about">
    <div class="about-section">
      <div class="title">
        <h1>about.</h1>
      </div>
      <div class="images-container" >
        <img src="../../../assets/about-us.gif" alt="about-us">
      </div>
      <div class="paragraph-container">

        <p lang="en-US">Buzzle is an artist who owes his holistic perception of the reality around him to his mental
          illness.</p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">His work is inspired by three main factors:</p>
        <p lang="en-US">1. Dedication of his life to analyses related to the true nature of reality, astronomy and
          metaphysics</p>
        <p lang="en-US">2. Perception of the world through the eyes of a person with asperger’s disease</p>
        <p lang="en-US">3. Fact that he witnessed a UFO sighting in his country of origin (yes, really)</p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">&nbsp;</p>
        <p><span lang="en-US">Our world is built on c</span><span lang="en-US">over-ups</span><span lang="en-US">.
                    Few manage to see it for what it really is. This is made more difficult by the fact that there are
                    theories that are hard to believe. </span><span lang="en-US">However</span> <span
            lang="en-US">history </span><span lang="en-US">show</span><span lang="en-US">s</span><span
            lang="en-US"> that even the most screwed up </span><span lang="en-US">conspiracy</span><span
            lang="en-US"> can turn out to be true, and what is commonly regarded as fact turns out to be false.
                </span></p>
        <p><span lang="en-US">&nbsp;</span></p>
        <p><span lang="en-US">He has always been associated with art, but he hasn’t always expressed it. Now he
                    releases unique 1/1 artworks in them</span><span lang="en-US">ed</span><span lang="en-US">
                    collections. Each one is a different message. Additionally, each piece of art in a collection
                    attempts to open </span><span lang="en-US">one’s</span><span lang="en-US"> mind and tells its own
                    story. </span><span lang="en-US">Every artpiece is here to provide great value to Collectors and NFT
                    community. The essence of art, according to the artist, is to awaken specific emotions in the
                    audience </span><span lang="en-US">by using audio and visual techniques</span><span lang="en-US">
                    and to influence the community.</span></p>
        <p><span lang="en-US">&nbsp;</span></p>
        <p lang="en-US">His art is unique 1/1 pieces and special series collectibles. His cryptographic art as NFT
          will remain on the blockchain forever. New DROPS are released on a regular basis, with an eye towards
          maximum profit for Collectors supporting the artist and his vision. For more information on specific
          collections, click&nbsp;<router-link to="/collections">here</router-link>
          .
        </p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">Each collection has a pricing strategy related to the number of artworks in the collection
          and their uniqueness. It is set for the highest return on investment for Collectors. Early investors are
          especially rewarded – the price increases with each drop. Read more about strategy and profitability for
          Collectors&nbsp;<router-link to="/to-collectors">here</router-link>
          . You will also find important technology information and the
          artist’s
          long-term plans&nbsp;<router-link to="/to-collectors">here</router-link>
          .
        </p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">&nbsp;</p>
        <p><span lang="en-US">„Buzzle” is a combination of the words „Buzz” and „Puzzle”. The first refers to outer
                    space. The second to the </span><span lang="en-US">mystery</span><span lang="en-US"> of finding
                </span><span lang="en-US">oneself</span><span lang="en-US"> in the veracity of our reality. Shit goes
                    even deeper than that. You have to put together a puzzle from scattered bits of information over
                    time to understand the bigger picture of the true nature of reality. </span><span
            lang="en-US">Buzzle i</span><span lang="en-US">dentity remains unknown at this </span><span
            lang="en-US">moment</span></p>
        <p></p>
        <p></p>
        <p>
          Art is art, but every Roadmap, in addition to art, is about business goals, social goals and more.
        </p>
        <p></p>
        <p></p>
        <p>
          Check out our <router-link to="/collectibles">Collectibles</router-link> Team <router-link to="/team">here</router-link>.

        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

.about {
  font-family: 'Josefin Sans', sans-serif;
  width: 1280px;
  max-width: 100%;
  padding: 20px;
  margin: 0 auto 100px auto;


}

</style>

<script>
export default {
  mounted() {
    document.title = this.page_title + ' - About'
  }
}
</script>