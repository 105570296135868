<template>
  <section id="home" class="home">
    <div class="home__main">
      <div class="col">
        <h1>
          aLIEnS.
        </h1>
        <div class="h4">
          1,000 NFT Collectibles
        </div>
        <div class="button">
          <a href="https://opensea.io/collection/1000aliens" target="_blank">
            <button>
              <img src="../../assets/opensea-logo.png" alt="opensea">
              OpenSea Market
            </button>
          </a>
        </div>
        <div class="social">
          <div class="icon">
            <a href="https://discord.com/invite/BreZcEMr7q" target="_blank">
              <i class="fab fa-discord"></i>
            </a>
          </div>
          <div class="icon">
            <a href="https://twitter.com/buzzle_art" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </div>
          <div class="icon">
            <a href="https://www.instagram.com/buzzle.art/" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div class="buttons">
          <div class="button">
            <a href="https://buzzle-art-media.s3.us-east-2.amazonaws.com/MANUAL_BUZZLE_EN_1_deb7667d72.pdf"
               target="_blank">
              <button>How to buy? (EN)</button>
            </a>
          </div>
          <div class="button">
            <a href="https://buzzle-art-media.s3.us-east-2.amazonaws.com/MANUAL_BUZZLE_PL_1_9585874ad2.pdf"
               target="_blank">
              <button>How to buy? (PL)</button>
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <p>Check Collection Stats LIVE <span class="live">🔴</span></p>
        <div class="stats" v-if="stats_loaded">
          <div class="stat" v-for="stat in stats" v-bind:key="stat.name">
            <div class="stat__value">
              {{ stat.value }}
            </div>
            <div class="stat__name">
              {{ stat.name }}
            </div>
          </div>
        </div>
        <div class="stats" v-else-if="!stats_loaded"
        >
          <span>👽<span class="live"> Fetching data from OpenSea...</span></span>
        </div>
        <div class="stats stats--gif">
          <img src="../../assets/about-us.gif" alt="aliens">
        </div>
      </div>
    </div>
    <div class="container">
      ⭐<span> Meet Secret Society Club</span>
      <div class="grid">
        <div class="member" v-for="member in members" v-bind:key="member.name">
          <div class="image"
               :style="'background-image: url('+member.normal_photo+')'">
            <img class="image-alien" :src="member.alien_photo" :alt="member.name">
          </div>
          <p>aLIEn #{{ member.alien_number }}</p>
          <p class="alien-number">{{ member.name }}</p>
          <p>
            <a :href="member.insta" target="_blank">
              <i class="fab fa-instagram"></i> {{ member.follow }}
            </a>
          </p>
        </div>
      </div>
      <router-link to="/members" class="more-link">
        <span>🚀 More</span>
      </router-link>
    </div>
    <div class="home__roadmap">
      <h2>Roadmap 1.0</h2>
      <div class="rocket">
        <span>🛸</span>
      </div>
      <div class="grid">
        <div class="phase">
          <h3>Phase 0</h3>
          <p class="name">🚀 SPACESHIP LAUNCH</p>
          <p>TEAM & OG's on BOARD</p>
          <img src="../../assets/phase0.png" alt="roadmap">
          <p>Buzzle project creation</p>
          <p>Team development</p>
          <p>Website & Social Media setup</p>
          <p>Community Building</p>
          <p>Whitelist Opening</p>
        </div>
        <div class="phase">
          <h3>Phase 1</h3>
          <p class="name">🪐 VENUS CONQUER</p>
          <p>20% aLIEnS on Board</p>
          <img src="../../assets/phase1.png" alt="roadmap">
          <p>Secret Society Club Establishment</p>
          <p>Own Social Network Launch</p>
          <p>Community Vote System Creation</p>
          <p>2 ETH Discord Giveaway</p>
          <p>Whitelist Spots Giveaway</p>
        </div>
        <div class="phase">
          <h3>Phase 2</h3>
          <p class="name">🌎 EARTH INVASION</p>
          <p>60% aLIEnS on Board</p>
          <img src="../../assets/phase2.png" alt="roadmap">
          <p>Space Invaders Game Remake</p>
          <p>Top Quality Merch</p>
          <p>Charity Donation</p>
          <p>2 ETH Discord Giveaway</p>
          <p>Whitelist Spots Giveaway</p>
        </div>
        <div class="phase">
          <h3>Phase 3</h3>
          <p class="name">👾 MARS OCCUPATION</p>
          <p>100% aLIEnS on Board</p>
          <img src="../../assets/phase3.png" alt="roadmap">
          <p>Community-Vote Arcade Game Remake</p>
          <p>Breeding NFTs for Club Members</p>
          <p>20 ETH Giveaway</p>
          <p>4 ETH Giveaway</p>
          <p>1% Secondary Sales - Community Wallet</p>
          <p>1% Secondary Sales - Club Members</p>
          <p style="color: #ff006a">Rodmap 2.0 Initiation</p>
        </div>
      </div>
    </div>
    <div class="container" style="background-color: white;">
      <div class="home__hero">
        <div class="social">
          <h1>🚀 BUZZLΞ's Instagram</h1>
          <iframe src="https://embedsocial.com/facebook_album/pro_hashtag/10b8baea9ca75f1ce9c5b2c28bdefa0357463636"
                  width="100%" height="600px" frameborder="0" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </div>
    <div class="container" style="background-color: black">
      <div class="home__hero ssc" style="color: white">
        <div class="info" style="color: white">
          <div class="ssc">
            <img src="../../assets/ssc-icon.png" alt="ssc">
            <div class="heading">
              <h3>Join Secret Society Club</h3>
              <h4>Owning aLIEn means you're there!</h4></div>
          </div>
          <div class="grid">
            <p><span>✨ Events</span>
              <br>
              <br>• Real-life SSC meet-ups , get to know famous actors, singers, entrepreneurs, bodybuilders, chefs and
              party alongside them!
              <br>• Metaverse – VR concerts, interesting talks, performances, hanging out with fellow aLIEns

            </p>
            <p><span>🌐 Networking</span>
              <br>
              <br>• Appear as an aLIEn in multiple Metaverses
              <br>• Receive extra rewards in our P2E Arcade Games platform
              <br>• Compete against the other members, solo and in teams!
              <br>• Get a merch of your choosing!
            </p>
            <p><span>💼 Voting</span>
              <br>
              <br>• Take part in creation of our future
              <br>• Choose between development priorities
              <br>• Vote for your favorite proposals
              <br>• Share new ideas and bring them into life
            </p>
          </div>
          <h4 style="text-align: center; margin-top: 50px;">
            aLIEnS' own Social Network Lanuch Soon!
          </h4>
        </div>
      </div>
    </div>
    <div class="container" style="background-color: white;">
      <h1 class="container__title">
        <i class="fab fa-youtube"></i>
        YouTube</h1>
      <div class="home__hero">
        <div class="col col--youtube">
          <iframe width="100%" height="400px" src="https://www.youtube.com/embed/F4ZdDkk0K1M"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
        <div class="col col--youtube">
          <iframe width="100%" height="400px" src="https://www.youtube.com/embed/s48Lm37tBqc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="home__hero">
        <div class="contact">
          <h3>Need help?</h3>
          <h5>Our Team will response you instantly.</h5>
        </div>
        <div class="contact">
          <h5><i class="fas fa-envelope"></i> support@buzzle-art.com</h5>
          <h5><i class="fab fa-discord"></i> discord.com/invite/BreZcEMr7q</h5>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.home {
  width: 100%;
  font-size: 16px;
  @media screen and(max-width: 992px) {
    font-size: 12px;
  }

  h1 {
    text-align: center;
    font-size: 2em;
    font-family: 'Press Start 2P', sans-serif;
  }

  &__roadmap {
    width: 1280px;
    max-width: 100%;
    margin: 50px auto;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;

    .fly {
      span {
        animation: fly 3s forwards;
        @keyframes fly {
          from {
            margin-left: 0;

          }
          to {
            margin-left: 17%;
          }
        }
      }
    }

    .rocket {
      @media screen and(max-width: 992px) {
        display: none;
      }

      span {
        //transform: rotate(30deg);
        font-size: 5em;

      }

      display: flex;
      align-items: center;
      height: 5px;
      width: 100%;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 157, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
      margin-top: 100px;
      margin-bottom: 100px;
    }

    h2 {
      font-family: 'Press Start 2P', sans-serif;
    }

    .grid {
      display: flex;
      justify-content: space-between;
      margin: 40px 10px;
      flex-wrap: wrap;

      .phase {
        max-width: 25%;
        @media screen and(max-width: 992px) {
          max-width: 50%;
          margin: 20px 0;
        }

        h3 {
          font-family: 'Press Start 2P', sans-serif;
        }

        p.name {
          color: #ff006a;
          font-size: 1em;
          margin-bottom: 10px;
        }

        p {
          font-size: .9em;
          margin: 0;
        }

        img {
          max-width: 100%;
          height: 120px;
          padding: 20px 30px;
        }
      }
    }
  }

  &__hero {
    text-align: center;
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-family: 'Josefin Sans', sans-serif;
    @media screen and(max-width: 992px) {
      flex-direction: column;
      padding: 0 20px;
    }

    .col {
      &--youtube {
        width: 100%;
        padding: 20px;
      }
    }

    .contact {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;

      h3, h5 {
        margin: 15px 0;
      }
    }

    .info {
      color: black;
      width: 100%;
      @media screen and(max-width: 992px) {
        width: 100%;
      }
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .ssc {
        display: flex;

        img {
          max-width: 120px;
        }
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr) !important;

        p {
          text-align: center;
        }
      }

      .saihoko-logo {
        max-width: 100%;
        width: 320px;
        margin-top: 100px;
        @media screen and(max-width: 992px) {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      .creators {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        p {
          padding: 5px 20px;
          margin: 0;
          font-size: 1rem;
        }
      }
    }

    .social {
      width: 100%;

      h1 {
        color: black;
        font-family: 'Josefin Sans', sans-serif;
      }

      @media screen and(max-width: 992px) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    .ssc {
      width: 40%;

      img {
        width: 100%;
      }
    }

    h2 {

    }
  }

  .ssc {
    @media screen and(max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .info {
      text-align: left;

      h3 {
        margin-bottom: 5px;
      }

      h4 {
        color: #cc3366;
        font-size: 1.3rem;
        margin-top: 5px;
      }

      p {
        font-size: 1rem;
        line-height: 36px;
        align-self: start;

        span {
          font-size: 1.6rem;

        }
      }
    }
  }

  &__main {
    width: 1280px;
    max-width: 100%;
    margin: 60px auto;
    padding: 0 20px;
    //text-align: center;
    font-family: 'Press Start 2P', sans-serif;
    display: flex;
    justify-content: space-between;
    @media screen and(max-width: 992px) {
      display: block;
    }

    .buttons {
      display: flex;
      margin-top: 40px;

      .button {
        padding-right: 25px;

        a {
          font-size: .8em;

          button {
            padding: 15px;
            background-color: #ff006a;
            color: white;
            border: none;
            border-radius: 5px;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: bold;
          }
        }
      }
    }

    .social {
      margin-top: 15px;
      display: flex;

      .icon {
        padding-right: 40px;
        @media screen and(max-width: 992px) {
          padding: 20px;
        }

        a {
          color: white;
          font-size: 2.4em;
        }
      }
    }

    h1 {
      font-size: 3.5em;
      margin: 20px 0;
    }


    .button {
      margin: 27.5px 0;

      a {
        text-decoration: none;

        &:hover {
          button {
            cursor: pointer;
          }
        }

        button {
          background: rgb(32, 129, 226);
          border: none;
          border-radius: 5px;
          color: white;
          font-family: 'Josefin Sans', sans-serif;
          display: flex;
          align-items: center;
          font-size: 1.2em;
          padding-right: 15px;

          img {
            width: 50px;
          }
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      @media screen and(max-width: 992px) {
        align-items: center;
      }
      //justify-content: center;
      p {
        text-align: center;
        font-family: 'Josefin Sans', sans-serif;
      }


      .stats {
        display: flex;
        font-family: 'Josefin Sans', sans-serif;
        text-align: center;
        flex-wrap: wrap;
        justify-content: space-around;

        &--gif {
          @media screen and(max-width: 992px) {
            display: none;
          }
        }

        img {
          width: 100%;
          margin-top: 50px;
          padding: 0 40px;
        }

        .stat {
          padding: 10px 20px;
          @media screen and(max-width: 992px) {
            padding: 10px;
          }

          &__value {
            font-size: 2.3em;
            @media screen and(max-width: 992px) {
              font-size: 1.6em;
            }
          }

        }
      }

      .saihoko {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 60px;

        p {
          margin: 0;
          padding-right: 20px;
        }

        img {
          align-self: end;
          width: 100%;
          max-width: 180px;
        }
      }
    }
  }

  .container {
    background: #cc3366;
    font-size: 1.8em;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;
    font-family: 'Josefin Sans', sans-serif;

    &__title {
      color: black;
      font-family: 'Josefin Sans', sans-serif;
    }

    .more-link {
      color: white;
      text-decoration: none;
    }

    span {
      font-family: 'Josefin Sans', sans-serif;
    }

    .grid {
      width: 1280px;
      max-width: 100%;
      margin: 0 auto;
      display: grid;
      padding-top: 30px;
      grid-template-columns: repeat(4, 1fr);
      @media screen and(max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      grid-gap: 15px;

      &--sm {
        margin-top: 15px;
        grid-template-columns: repeat(8, 1fr);
      }

      .member {
        p {
          font-size: 1.4rem;
          margin: 10px 0;
          font-weight: bold;
          text-align: center;

          a {
            color: white;
            text-decoration: none;

            i {
              color: white;
              padding: 0 5px;
            }
          }
        }

        .alien-number {
          font-size: .7em;
          color: black;
        }

        .image {
          display: flex;
          background-size: cover;
          background-position: center;
        }

        .image-normal {
          display: none;
        }


        @keyframes fadeIn {
          from {
            top: -320px;
          }
          to {
            top: 0;
          }
        }

        @keyframes fadeOut {
          from {
            top: 0;
          }
          to {
            top: -320px;
          }
        }

        .image {
          //border: 2px solid white;
          width: 100%;
          //height: 300px;
          overflow: hidden;
          background-size: cover;
          background-position: top;

          .image-alien {
            opacity: 1;
            transition: 1s;
          }

          &:hover {
            .image-alien {
              opacity: 0;
            }
          }

          img {
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }

    }
  }

  &__bar {
    width: 100%;
    background: #ff006a;
    height: 40px;

    h3 {
      color: white;
    }
  }
}

.live {
  animation: live infinite 2s;
  @keyframes live {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
<script>
import axios from 'axios'

export default {
  data: () => {
    return {
      stats_loaded: false,
      stats: {
        total_sales: {
          value: 0,
          name: '🏷️ Total sales'
        },
        num_owners: {
          value: 0,
          name: '👽 Owners'
        },
        total_volume: {
          value: 0,
          name: '💰 Total Volume Ξ'
        },
        average_price: {
          value: 0,
          name: '💸 Average price Ξ'
        },
        floor_price: {
          value: 0,
          name: '💵 Floor price Ξ'
        }

      },
      creators: [
        'lukasz.jewasinski',
        'antoni.krolikowski',
        'g0rny_1',
        'miki_ityle',
        'kromeek',
        'bibiffee'
      ],
      members: [
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/gf_Gep_Z_a_Wg_T_Eayo_antoni_krolikowski_664x442_nocrop_ecee2b24a2.jpg',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/small_CRYPTO_RABBIT_807c2fec3e.png',
          name: '@antek.krolikowski',
          follow: '491k',
          alien_number: '105',
          insta: 'https://www.instagram.com/antek.krolikowski/?hl=pl',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/hr_Xktkp_TURB_Xy9i_Mz_E0_ZWE_2_N2_Q5_N2_Ez_ZT_Ay_Yz_E1_NWY_4_Mj_Fk_OW_Iw_N_Dhm_MS_5qc_Ge_Sl_QM_Az_Kv_NFWDND_Aa_T_Bc0_Bp_M0_BLA_38266592b2.jfif',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_113_2d96807d5b.png',
          name: '@liroy_marzec',
          follow: '11.4k',
          alien_number: '113',
          insta: 'https://www.instagram.com/liroy_marzec/',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/Joanna_Opozda_padla_ofiara_sutenerow_Moj_strach_i_placz_na_nikim_nie_robily_wrazenia_FOTO_article_491d70ba1c.jpg',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_116_6f5c412b12.jpg',
          name: '@asiaopozda',
          follow: '438k',
          alien_number: '116',
          insta: 'https://www.instagram.com/asiaopozda/?hl=pl',
        },
        {
          normal_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/SSC_ICON_1_debc344fad.png',
          alien_photo: 'https://buzzle-art-media.s3.us-east-2.amazonaws.com/a_LI_En_118_d089b3ca5f.jpg',
          name: '@maffashion_official',
          follow: '1.4 M',
          alien_number: '118',
          insta: 'https://www.instagram.com/maffashion_official/?hl=pl',
        },
      ],
      sell: new Date('01-06-2022')
    }
  },

  mounted() {
    document.title = this.page_title
    axios.get('https://api.opensea.io/api/v1/collection/1000aliens/stats').then(r => {
      this.stats.average_price.value = (Math.round(r.data.stats.average_price * 100) / 100).toFixed(2);
      this.stats.total_volume.value = (Math.round(r.data.stats.total_volume * 100) / 100).toFixed(2);
      this.stats.total_sales.value = r.data.stats.total_sales;
      this.stats.floor_price.value = (Math.round(r.data.stats.floor_price * 100) / 100).toFixed(2);
      this.stats.num_owners.value = r.data.stats.num_owners;
      this.stats_loaded = true;
    }).catch(e => {
      console.error(e);
    })


    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fly');
        }
      });
    });

    observer.observe(document.querySelector('.rocket'));


  },
  methods: {}
}
</script>