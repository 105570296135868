import Vue from 'vue'
import VueRouter from 'vue-router'
import VueLazyload from 'vue-lazyload'
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'


import App from './App';
import routes from './routes'


Vue.config.productionTip = false

Vue.use(VueLazyload)


Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
})

Vue.use(VueRouter)

Vue.mixin({
  data: function() {
    return {
      page_title: process.env.VUE_APP_PAGE_TITLE
    }
  }
})



const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: 'history'
});



export default router;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')