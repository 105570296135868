<template>
  <section class="all-aliens" @scroll="scrolling">
    <div class="row">
      <div v-lazy-container="{ selector: 'img' }" class="alien" v-for="alien in aliens" v-bind:key="alien.id">
        <router-link :to="'/collectibles/alien/'+alien.number_id">
          <img :data-src="alien.avatar.formats.small.url" :alt="alien.number">
          <p>aLIEn {{ alien.number }}</p>
        </router-link>
      </div>
    </div>
    <h5 v-if="loading">aLIEnS. Invading...</h5>
    <h5 v-if="error" style="animation: none; font-size: 1.3rem;">{{ error }}</h5>
  </section>
</template>
<style lang="scss" scoped>
.all-aliens {
  width: 100%;
  padding: 0 30px;
  margin-top: 100px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 100%;
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1)
    }
    50% {
      transform: scale(1.05)
    }
    100% {
      transform: scale(1)
    }
  }

  h5 {
    font-family: 'Press Start 2P', cursive;
    color: #ff006a;
    font-size: 2rem;
    text-align: center;
    animation: bounce 1s infinite;
    max-width: 1024px;
    width: 100%;
    margin: 50px auto;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    @media screen and(max-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and(max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and(max-width: 512px) {
      grid-template-columns: repeat(2, 1fr);
    }
    //@media screen and(max-width: 380px) {
    //  grid-template-columns: repeat(1, 1fr);
    //}
    grid-gap: 15px;

    .alien {
      //grid-column: 1;
      overflow: hidden;

      a {
        color: white;
        text-decoration: none;
        display: block;

        &:hover {
          p {
            color: #cc3366;
          }
        }

        img {
          width: 100%;
          transition-duration: .5s;
        }

        img[lazy=loading] {
          opacity: 0;
        }

        img[lazy=error] {
          background: #cc3366;
        }

        img[lazy=loaded] {
          //animation: fadeIn 2s;

        }

        p {
          font-family: 'Press Start 2P', cursive;
          text-align: center;
          @media screen and(max-width: 738px) {
            font-size: .8rem;
          }
        }
      }
    }
  }

}
</style>
<script>
let api_url = process.env.VUE_APP_API_URL
import axios from 'axios'

export default {
  data: function () {
    return {
      aliens: [],
      aliens_props: {
        total: null,
        amount: 20,

      },
      loading: true,
      api_url: api_url,
      run_scroll_event: true,
      error: null,
    }
  },
  methods: {
    async addAliens(amount = null) {
      if (this.aliens.length < this.aliens_props.total) {
        this.loading = true;
        try {
          await axios.get(api_url + '/aliens?_sort=number_id&_start=' + this.aliens.length + '&_limit=' + (amount ? amount : this.aliens_props.amount)).then(r => {
            for (let alien of r.data) {
              this.aliens.push(alien);
            }
            // this.aliens.sort((a, b) => (a.number_id > b.number_id) ? 1 : -1);
            this.loading = false;
            this.run_scroll_event = true;
          })
        } catch (e) {
          this.loading = false;
          this.error = 'Our servers are down. Please try again later.';
        }
      }
    },
    scrolling() {
      let limit = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight)
      if ((limit - window.scrollY) <= 300 && this.run_scroll_event) {
        this.run_scroll_event = false;
        setTimeout(() => {
              this.addAliens();
            }, 100
        )
      }
    }
  },
  async mounted() {
    document.title = this.page_title + ' - aLIEnS'
    try {
      await axios.get(api_url + '/aliens/count').then(r => {
        this.aliens_props.total = r.data;
        this.addAliens(20);
      })
    } catch (e) {
      this.loading = false;
      this.error = 'Our servers are down. Please try again later.';
    }

    window.addEventListener('scroll', this.scrolling)

  }
}
</script>