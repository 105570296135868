<template>
  <section class="to-collectors">
    <div class="aliens__buttons">
      <a @click="scrollToEL('creator')" class="btn_pagination faq">
        <button>Creator</button>
      </a>
      <a @click="scrollToEL('art-pieces')" class="btn_pagination">
        <button>Art pieces</button>
      </a>
      <a @click="scrollToEL('metaverse')" class="btn_pagination">
        <button>Metaverse</button>
      </a>
      <a @click="scrollToEL('technical-stuff')" class="btn_pagination technical">
        <button>Technical stuff</button>
      </a>
      <a @click="scrollToEL('faq')" class="btn_pagination faq">
        <button>FAQ</button>
      </a>
    </div>
    <div class="header__container">
      <h2 class="title__of__about__section">to collectors.</h2>
    </div>
    <div class="paragraph__container">

      <p>The artist’s entire strategy is set up to make it as profitable as possible for each Collector to invest
        in BUZZLE art. Early investors are especially rewarded – the price increases in each collection with
        every DROP.</p>
      <p>&nbsp;</p>
      <p>Each of the artist’s collections tells a different story, but they are all connected. Each is aligned
        with the artist’s vision and overall perception of reality. Read more about it&nbsp;<router-link to="/about">
          here
        </router-link>.
      </p>
      <h2 class="sub-title" style="margin-top: 30px; margin-bottom: 0px;">BUZZLE’s work is divided into several
        streams.</h2>
    </div>
    <div class="about-section" id="creator">
      <div class="images-container">
        <img src="../../../assets/about-us.gif" alt="about-us">
      </div>
      <div class="paragraph-container">

        <p lang="en-US">Buzzle is an artist who owes his holistic perception of the reality around him to his mental
          illness.</p>
        <p lang="en-US">His work is inspired by three main factors:</p>
        <p lang="en-US">1. Dedication of his life to analyses related to the true nature of reality, astronomy and
          metaphysics</p>
        <p lang="en-US">2. Perception of the world through the eyes of a person with asperger’s disease</p>
        <p lang="en-US">3. Fact that he witnessed a UFO sighting in his country of origin (yes, really)</p>
        <p><span lang="en-US">Our world is built on c</span><span lang="en-US">over-ups</span><span lang="en-US">.
                    Few manage to see it for what it really is. This is made more difficult by the fact that there are
                    theories that are hard to believe. </span><span lang="en-US">However</span> <span
            lang="en-US">history </span><span lang="en-US">show</span><span lang="en-US">s</span><span
            lang="en-US"> that even the most screwed up </span><span lang="en-US">conspiracy</span><span
            lang="en-US"> can turn out to be true, and what is commonly regarded as fact turns out to be false.
                </span></p>
        <p><span lang="en-US">&nbsp;</span></p>
        <p><span lang="en-US">He has always been associated with art, but he hasn’t always expressed it. Now he
                    releases unique 1/1 artworks in them</span><span lang="en-US">ed</span><span lang="en-US">
                    collections. Each one is a different message. Additionally, each piece of art in a collection
                    attempts to open </span><span lang="en-US">one’s</span><span lang="en-US"> mind and tells its own
                    story. </span><span lang="en-US">Every artpiece is here to provide great value to Collectors and NFT
                    community. The essence of art, according to the artist, is to awaken specific emotions in the
                    audience </span><span lang="en-US">by using audio and visual techniques</span><span lang="en-US">
                    and to influence the community.</span></p>
        <p><span lang="en-US">&nbsp;</span></p>
        <p lang="en-US">His art is unique 1/1 pieces and special series collectibles. His cryptographic art as NFT
          will remain on the blockchain forever. New DROPS are released on a regular basis, with an eye towards
          maximum profit for Collectors supporting the artist and his vision. For more information on specific
          collections, click&nbsp;<router-link to="/collections">here</router-link>.
        </p>
        <p lang="en-US">&nbsp;</p>
        <p lang="en-US">Each collection has a pricing strategy related to the number of artworks in the collection
          and their uniqueness. It is set for the highest return on investment for Collectors. Early investors are
          especially rewarded – the price increases with each drop. Read more about strategy and profitability for
          Collectors&nbsp;<router-link to="/to-collectors">here</router-link>.
          You will also find important technology information and the artist’s long-term plans<router-link to="/to-collectors">here</router-link>.
        </p>
        <p lang="en-US">&nbsp;</p>
        <p><span lang="en-US">„Buzzle” is a combination of the words „Buzz” and „Puzzle”. The first refers to outer
                    space. The second to the </span><span lang="en-US">mystery</span><span lang="en-US"> of finding
                </span><span lang="en-US">oneself</span><span lang="en-US"> in the veracity of our reality. Shit goes
                    even deeper than that. You have to put together a puzzle from scattered bits of information over
                    time to understand the bigger picture of the true nature of reality. </span><span
            lang="en-US">Buzzle i</span><span lang="en-US">dentity remains unknown at this </span><span
            lang="en-US">moment</span></p>
        <p></p>
        <p></p>
        <p>
          Art is art, but every Roadmap, in addition to art, is about business goals, social goals and more.
        </p>
        <p></p>
        <p></p>
        <p>
          Check out our
          <router-link to="/collectibles">Collectibles</router-link>
          Team
          <router-link to="/team">here</router-link>.

        </p>
      </div>
    </div>
    <div class="paragraph__container" id="art-pieces">
      <p></p>
      <p></p>
      <p></p>
      <h2 class="section-title">Unique 1/1 Collectibles</h2>
      <p><b><u>One path</u></b> follows the Collectibles series along with Roadmaps. See Roadmap 1.0&nbsp;<router-link
          to="/collectibles/aliens">here
      </router-link>. Priorities
        here are community related activities and targets.&nbsp;
      </p>
      <p>The average price of 1 NFT Collectible from the series aLIEnS is much higher than the prices of pieces in
        DROP 1, DROP 2, or DROP 3. The price increases with each DROP so the Collectors who join the Club faster
        will have especially profitable opportunities.</p>
      <p>Every Roadmap ends with Breeding. New aLIEnS from another dimension arrive. A new series of Collectibles
        is initiated.</p>
      <p>A New Collectibles series starts a new Roadmap with new activities and social targets. Each Collectibles
        collection will be connected to each other.</p>
      <p>&nbsp;</p>
      <p>The original aLIEnS collection is the artist’s most important Collectibles series. It is the only
        collection in which the number of artpieces is 1,000. Every future collection of Collectibles (Roadmap
        2.0, Roadmap 3.0…) will have at least 10,000 Collectibles.</p>
      <p>Collectors who will be the current owners of works from the 1,000 aLIEnS collection, at each Breeding
        (subsequent Roadmaps) have the opportunity to claim 1 random Collectibles piece from the new series as
        part of their loyalty to the Club.</p>
      <p>Any collector who owns the original aLIEnS from the first collection becomes an honorary member of Secret
        Society Club. This is the highest level and allows you to enjoy all the activities the Club has to
        offer.</p>
      <p>Any collector who joins the Club in subsequent Collectibles will, of course, have access to all core
        activities and objectives achieved in connection with the Roadmap- including core activities from
        earlier Roadmaps (such as Space Invaders remake), Merch, and in the near future- Amusement Arcade
        Platform.</p>
      <p>Honorary members, however, will have several benefits of being an early club member. The artist rewards
        loyalty and support from community from the very beginning.</p>
      <p>The artist also reserves the right to initiate special activities, i.e. events, for honorary Club members
        only.</p>
    </div>

    <div class="paragraph__container">

      <h2 class="section-title-second">Unique 1/1 Artpieces</h2>
      <p><u><b>The second path</b></u> is telling a story through the unique and individual works of an artist-
        each collection is another chapter (Chapter 1, Chapter 2…).</p>
      <p>There are 10 art pieces in each Chapter. Every piece of art is a different message and a short
        mind-opening story.</p>
      <p>Each artwork from „Chapter” collections will automatically be an honorary membership to BAC.</p>
      <p>&nbsp;</p>
      <p>The art of BUZZLE, besides the additional purposes visible on the following Roadmaps, is primarily
        intended to provide real value and evoke specific emotions.</p>
      <p>The artist tells a story through subsequent artworks, and everything is the result of his perception of
        the surrounding reality.</p>

    </div>

    <div class="paragraph__container" id="metaverse">

      <h2 class="section-title-second">Metaverse</h2>
      <p>The world of the Meraverse is still unexplored. The artist believes that the increase in the prevalence
        of NFT in the coming years will cause us to move from the real to the virtual world.</p>
      <p>All of the artist’s activities are aimed at better understanding the upcoming breakthrough and new
        reality. As the community grows new initiatives related to the Metaverse will be launched.</p>
    </div>

    <div class="paragraph__container" id="technical-stuff">

      <h2 class="section-title-second">Technical Stuff</h2>
      <p>All artpieces are handcrafted and stored on decentralized „Ethereum’s Internet of Blockchains”- Polygon.
        Read more about Polygon&nbsp;<a href="https://polygon.technology/" target="_blank"
                                        rel="noopener">here</a>.</p>
      <p>The reason is to reduce the costs associated with buying and selling artworks (Ethereum gas fees).
        Polygon brings the best of where Ethereum has taken us, but improves certain features and makes it
        easier for the community to access the artist’s works and the club.</p>
      <p>As a result, buying and selling is without gas fees. In addition to easier access and fewer fees for the
        community, Polygon is a very developmental environment with which the artist has further plans for
        future Roadmaps (Metaverse stuff and Amusement Arcade Platform).</p>
      <p>Of course, the Polygon environment works closely with the OG Ethereum blockchain-all artwork is
        Ethereum-compatible.</p>
      <p>Polygon as well as Ethereum allows you to produce any code on the blockchain, and the results of the
        execution are stored forever.</p>
      <p>Once published on the blockchain, the collection pieces are there permanently and cannot be modified in
        any way. Among other things, this is the reason for their uniqueness and the certainty that only certain
        amount of pieces exist.</p>
      <p>Each piece of artwork, after being hand-crafted, with the cooperation of an algorithm that helps
        determine specific percentages of features in the collection, is placed on Polygon.</p>
      <p>During this process, the name, traits, original MP4 file, description, and JPEG file as cover are
        uploaded. As additional content, each NFT contains the original source file (MP4) and a cover for use in
        social media (JPEG)-all additional files are stored on a decentralized server using IPFS. The buyer will
        receive a link to download the assets from the decentralized storage.</p>
      <p>Then, once the NFT is created, its Metadata is frozen. Metadata is all the information about the NFT
        entered during its creation. Freezing is the process that permanently „freezes” data on decentralized
        storage. This is why once an NFT is created and frozen, it is 100% stored on the decentralized network
        and the artist cannot change anything about its contents or data.</p>
    </div>

    <div class="paragraph__container" id="faq">

      <h2 class="section-title-second">FAQ</h2>
      <h3 class="section-title-Third">How to get your own artpiece?</h3>
      <p>1. Download and install a Chrome browser plugin MetaMask. Doing so will allow authorized websites to
        access your Ethereum account. Instructions on how to install MetaMask can be found&nbsp;<a
            href="https://metamask.io/faqs">here</a>.</p>
      <p>2. Once you have created an account, buy the desired amount of ETH. Instructions on how to buy ETH
        through MetaMask can be found&nbsp;<a href="https://metamask.io/faqs" target="_blank"
                                              rel="noopener">here</a>.</p>
      <p>3. Generally, its good to go.&nbsp;However, all the artist’s works are stored on „Ethereum’s Internet of
        Blockchains”- Polygon. Read more about Polygon&nbsp;<a href="https://polygon.technology/"
                                                               target="_blank" rel="noopener">here</a>.</p>
      <p>Above you can read about what benefits Polygon brings.</p>
      <p>As a result, buying and selling is without gas fees. However, to be able to buy works on the Polygon
        blockchain, you need to click in one place in your wallet on OpenSea. Check this out <span
            style="background-color: #000000;"><a
            href="https://support.opensea.io/hc/en-us/articles/1500012881642-How-do-I-transfer-ETH-from-Ethereum-to-Polygon-">here</a></span>.
      </p>
      <p>4. After installing the plugin, specific sites will recognize it. This allows you to bid, auction, buy
        and sell collectible art on the marketplace. For example, you can buy aLIEn&nbsp;<a
            href="https://opensea.io/collection/1000aliens" target="_blank" rel="noopener">here</a>.</p>
      <h3 class="section-title-Third">Release date</h3>

      <p>First DROP release date will be revealed via&nbsp;<a
          href="https://discord.com/channels/880564549181399081/891444749683273749" target="_blank"
          rel="noopener">Discord&nbsp;</a>(@announcements)</p>
      <p>Avoid FOMO and <a href="https://discord.com/channels/880564549181399081/880564549181399083">join</a>
        community!</p>

      <h3 class="section-title-Third">Where to get artpieces?</h3>
      <p>All artworks and collectibles will be available to purchase in public sale on OpenSea.</p>
      <p>However, there is an opportunity to make offers before the public sale begins. Actually, offers can be
        made at any time. The current owner can accept it or not. See below what is the difference between
        buying from primary and secondary market.</p>

      <h3 class="section-title-Third">Which blockchain is used by artist?</h3>
      <p>All of the artist’s artworks are on the Polygon Blockchain. Read more about Polygon <a
          href="https://polygon.technology/" target="_blank" rel="noopener">here</a>.</p>

      <h3 class="section-title-Third">Why Polygon?</h3>
      <p>The reason is to reduce the costs associated with buying and selling artworks (Ethereum gas fees).
        Polygon brings the best of where Ethereum has taken us, but improves certain features and makes it
        easier for the community to access the artist’s works and the club.</p>
      <p>As a result, buying and selling is without gas fees. In addition to easier access and fewer fees for the
        community, Polygon is a very developmental environment with which the artist has further plans for
        future Roadmaps (Metaverse stuff and Amusement Arcade Platform).</p>
      <p>Of course, the Polygon environment works closely with the OG Ethereum blockchain-all artwork is
        Ethereum-compatible.</p>
      <p>Polygon as well as Ethereum allows you to produce any code on the blockchain, and the results of the
        execution are stored forever.</p>

      <h3 class="section-title-Third">What is the pricing structure?</h3>
      <p>Each collection has a pricing strategy related to the number of artworks in the collection and their
        uniqueness. It is set for the highest return on investment for Collectors. Early investors are
        especially rewarded – the price increases with each DROP.</p>

      <h3 class="section-title-Third">Why are prices going up?</h3>
      <p>Just because. JK.</p>
      <p>The prices in each collection increase with the DROP. The reason is that with each DROP the number of
        available works on the primary market decreases. See further what the difference is between primary and
        secondary purchase.</p>
      <p>Another reason is that, collectors who join the club earlier, have more benefits. Faith in the artist and
        his vision is rewarded. Everyone wants to be in the project as it is popular, but true Secret Society
        Club members are in the club not only for the possible financial benefits, but because they believe in
        the project from the beginning.</p>
      <p>Additional reasons relate to liquidity and distribution consistent with the spirit of the club and the
        artist’s vision.</p>

      <h3 class="section-title-Third">Roadmap?</h3>
      <p>aLIEnS and each future Collectible series has its own Roadmap. See Roadmap 1.0. Priorities here are community
        related activities and targets. See Roadmap 2.0 sneak peak here.</p>
      <p>Every Roadmap ends with Breeding. New aLIEnS from another dimension arrive. A new series of Collectibles is
        initiated.</p>
      <p>A New Collectibles series starts a new Roadmap with new activities and community-focus targets.</p>
      <p>The artist wants to give as much as possible to the community. Each phase of the Roadmap as the project
        develops will be refined with the best interests of the community and club members in mind.</p>

      <h3 class="section-title-Third">Artist's long-term vision?</h3>
      <p>The aLIEnS and Chapter 1.0 collections along with the Roadmap 1.0 assumptions are just the beginning.</p>
      <p><a style="background-color: #000000;" href="#art" target="_blank" rel="noopener">Here’s</a>&nbsp;a sneak
        peek of future Roadmap 2.0.</p>
      <p>In addition to the Collectibles series from Roadmap, the artist simply wants to create more artwork and
        tell a story according to his own vision.</p>
      <p>There will also be special collections unrelated to the Collectibles and Chapter series. Everything is a
        secret at this point and will be revealed to the community in proper time.</p>
      <p>The artist has always been involved with art and graphic design. Thanks to NFT he can do what he loves
        and what he was created for – there is no way back.</p>

      <h3 class="section-title-Third">What is the difference between purchasing an alien from the primary market
        vs purchasing one from the secondary?</h3>

      <p>Having aLIEn makes you an honorary member of Secret Society Club. You have access to all the activities
        that Roadmap offers. Why honorary? Future Collectibles will also entitle you to Secret Society Club, but
        the first collection is only one – aLIEnS owners will have special benefits of owning aLIEn from the OG
        collection in the future. It doesn’t matter if you buy aLIEn from primary or secondary sale- you are
        still a current member of the club.</p>
      <p>So what’s the deal with buying from the primary market? Well, the artist wanted to give back to the
        community and set a reward of 50 ETH for one randomly chosen adress. The raffle will include addresses
        that were the original and first owner of each aLIEn.</p>
      <p>The raffle will take place before Breeding, and therefore at the end of Roadmap 1.0.</p>

      <h3 class="section-title-Third">How to become a Secret Society Club member?</h3>
      <p>If you own aLIEn, you are a member of the club.</p>

      <h3 class="section-title-Third">How to take part in the 20 ETH raffle?</h3>
      <p>Addresses that first own each of the 1,000 aLIEns, and therefore purchase from primary market, will
        participate in the 20 ETH raffle after Roadmap 1.0.</p>

      <h3 class="section-title-Third">What about the giveaways?</h3>
      <p>In addition to the 20 ETH raffle, there will be a total of 10 ETH in 10 giveaways on Discord.</p>
      <p>2 giveaways each time a Phase is completed.</p>
      <p>As below:</p>
      <p>Phase 1 completed<br>2x 1 ETH</p>
      <p>Phase 2 completed<br>2x 1 ETH</p>
      <p>Phase 3 completed<br>2x 1 ETH</p>
      <p>Phase 4 completed<br>2x 1 ETH</p>
      <p>Phase 5 completed<br>2x 1 ETH</p>
      <p>You can find the details of how to participate in the giveaways on&nbsp;<a
          href="https://discord.com/channels/880564549181399081/895982480648183818" target="_blank"
          rel="noopener">Discord&nbsp;</a>(@giveaways)</p>
      <p>In addition to the ETH giveaway, upon completion of Phase 2, the existing 10 club members will be awarded
        1 randomly selected aLIEn.</p>

      <h3 class="section-title-Third">What makes the project unique?</h3>
      <p>Just everything.</p>
      <p>Here are some highlights/</p>
      <p>/Secret Society Club</p>
      <p>/Retro vibes</p>
      <p>/Arcade Games</p>
      <p>/No gas fees</p>
      <p>/Community-focus</p>
      <p>/Total of over 30 ETH + 10 aLIEnS in giveaways in Roadmap 1.0 only</p>
      <p>/Breeding with the opportunity for club members to pick up 1 random Collectible from the new series</p>
      <p>/Metaverse stuff</p>
      <p>/Merch</p>
      <p>/Handcrafted</p>
      <p>/Floor in 1st DROP is 0.01 ETH</p>
      <p>/Medium price among 1,000 aLIEnS on primary market aimed to be around 0.5 ETH</p>
      <p>/Charity</p>
      <p>/
        <router-link to="/collectibles/metaliens">Roadmap 2.0</router-link>
        sneak peak
      </p>
      <p>Join <a href="https://discord.com/channels/880564549181399081/880564549181399083">Discord</a> so you
        don’t miss out.</p>

      <h3 class="section-title-Third">Who is BUZZLE?</h3>
      <p>Check it out
        <router-link to="/about"> here</router-link>
        .
      </p>

      <h3 class="section-title-Third">Pre-Sale?</h3>
      <p>No.</p>

      <h3 class="section-title-Third">White List?</h3>
      <p>Check it out <router-link to="/whitelist">here</router-link>.</p>

      <h3 class="section-title-Third">Owners Raffle?</h3>
      <p>No.</p>

      <h3 class="section-title-Third">Gass fees?</h3>
      <p>NO GAS FEES.</p>
      <p>0%</p>

    </div>
  </section>
</template>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

.about-section {
  h1 {
    text-transform: uppercase;
    text-align: center;
    padding: 40px 0;
    font-size: 40px;
  }

  p {
    text-align: center;
    font-size: 1.3rem;
    padding: 10px 0;

    a {
      color: #ff006a;
      text-decoration: none;
    }
  }

  .images-container {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
    }

    margin: 60px 0;
  }
}

.to-collectors {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 200px;
}

.header__container {
  position: relative;
  align-self: center;
  height: 50px;
  width: auto;
  margin-top: 55px;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: center;
}

.images__container {
  display: flex;
  position: relative;
  align-self: center;
  flex-direction: column;
  height: 300px;
  width: 100%;
  margin: 10px;
  text-transform: uppercase;
  text-align: center;
}

.button__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  position: relative;
  align-self: center;
  flex-direction: column;
  width: auto;
  justify-content: center;
  @media screen and(min-width: 997px) {
    //margin-top: 100px;
    grid-template-columns: repeat(4, 1fr);
    a {
      margin: auto 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}


.title__of__about__section {
  font-size: 40px;
  color: white;
  font-family: "Josefin Sans", sans-serif;

}

.paragraph__of__about__section {
  align-self: center;
  color: white;
  font-size: 16px;
}

.paragraph__container {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  align-self: center;

}

.photo_column {
  align-self: center;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 310px;
  margin: 15px;
}

.btn_pagination {
  align-self: center;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 500;
  width: 100%;
  font-size: 1.2rem;
  -webkit-border-radius: 5px;
  padding: 20px 15px;
  text-align: center;
}


.sub-title {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-weight: 500;
  margin-top: 150px;
  margin-bottom: 150px;
}

.section-title {
  font-size: 2rem;
  margin: 10px;
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-weight: 500;
}

.section-title-second {
  font-size: 2rem;
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-weight: 500;
  margin: 100px 10px 10px;
}

.section-title-Third {
  font-size: 25px;
  font-family: "Josefin Sans", sans-serif;
  color: #FF006A;
  font-weight: 500;
  margin: 50px 10px 30px;
}

.height__box {
  height: 250px;
}

p {
  font-size: 19px;
  font-weight: 400;
  color: white;
  margin: 10px;
  font-family: "Josefin Sans", sans-serif;
}

a {
  color: #c36;
  font-family: "Josefin Sans", sans-serif;
  text-decoration: none;
}

</style>

<script>
export default {
  methods: {
    scrollToEL(id) {
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({behavior: 'smooth'});
    }
  },
  mounted() {
    document.title = this.page_title + ' - To Collectors'
  }
}
</script>