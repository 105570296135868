<template>
  <section class="team">
    <div class="team__heading">
      <div class="title">
        <h1>Team.</h1>
        <p>Art is art, but every
          <router-link to="/collectibles/aliens/all"> Roadmap</router-link>, in addition to art, is about business goals, social goals and more.
        </p>
        <p>Meet our
          <router-link to="/collectibles">Collectibles</router-link>
          core Team below!
        </p>
        <p>New honorary Team members will be announced
          prior to launch. Stay tuned.</p>
        <p>For the record-we are all aliens. Everyone specializes in a
          different field. Together on a mission to bring value, knowledge and life changing utilities to the
          community. </p>

      </div>
    </div>
    <h5 v-if="loading">Loading content...</h5>
    <h5 v-if="error" style="animation: none; font-size: 1.3rem;">{{ error }}</h5>
    <section v-if="team" class="team__members">
      <div class="member" v-for="(member, i) in 8" v-bind:key="i">
        <div class="member__avatar" v-lazy-container="{ selector: 'img' }">
          <img :data-src="team[i].avatar.formats.small.url" :alt="team[i].id">
        </div>
        <h1 class="member__name">
          {{ team[i].name }}
        </h1>
        <h4 class="member__description" v-html="team[i].description"></h4>
        <div class="member__social">
          <div class="item" v-for="item in team[i].social" v-bind:key="item.link">
            <a :href="item.link" target="_blank">
              <i :class="item.icon"></i>
            </a>
          </div>
        </div>
        <p class="member__long-description" v-html="team[i].long_description"></p>
      </div>
    </section>
    <!-- For BAYC -->
    <section v-if="team" class="team__members">
      <div class="member" v-for="(member, i) in 2" v-bind:key="i">
        <div class="member__avatar" v-lazy-container="{ selector: 'img' }">
          <img :data-src="team[i+8].avatar.formats.small.url" :alt="team[i+8].id">
        </div>
        <h1 class="member__name">
          {{ team[i + 8].name }}
        </h1>
        <h4 class="member__description" v-html="team[i+8].description"></h4>
        <div class="member__social">
          <div class="item" v-for="item in team[i+8].social" v-bind:key="item.link">
            <a :href="item.link" target="_blank">
              <i :class="item.icon"></i>
            </a>
          </div>
        </div>
        <p class="member__long-description" v-html="team[i+8].long_description"></p>
      </div>
    </section>
    <section class="team__partners">
      <h1>PARTNERS.</h1>
      <div class="box">
        <div class="box__item">
          <a href="https://polygon.technology" target="_blank">
            <img src="../../../assets/polygon.png" alt="polygon">
          </a>
        </div>
        <div class="box__item">
          <a href="https://polygonstudios.com/" target="_blank">
            <img src="../../../assets/polygon.gif" alt="polygon-studios">
          </a>
        </div>
        <div class="box__item">
          <a href="https://opensea.io/" target="_blank">
            <img src="../../../assets/opensea.png" alt="opensea">
          </a>
        </div>
        <div class="box__item">
          <a href="https://saihokonft.com/" target="_blank">
            <img src="../../../assets/saihokonft.png" alt="saihokonft">
          </a>
        </div>
        <div class="box__item">
          <a href="https://moralis.io/" target="_blank">
            <img src="../../../assets/moralis.png" alt="saihokonft">
          </a>
        </div>
        <div class="box__item">
          <a href="https://codeclutch.dev/" target="_blank">
            <img src="../../../assets/codeclutch.png" alt="codeclutch">
          </a>
        </div>
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.05)
  }
  100% {
    transform: scale(1)
  }
}

h5 {
  font-family: 'Press Start 2P', cursive;
  color: #ff006a;
  font-size: 1.6rem;
  text-align: center;
  animation: bounce 1s infinite;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto 80px;
}

.team {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto 100px;

  &__partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Josefin Sans', sans-serif;
      text-align: center;
      font-size: 2rem;
    }

    .box {
      margin-left: 10px;
      display: grid;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      @media screen and(max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and(max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }

      &__item {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        img {
          max-width: 200px;
        }
      }
    }
  }

  &__members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and(max-width: 992px) {
      .member {
        width: 50% !important;
      }
    }
    @media screen and(max-width: 468px) {
      .member {
        width: 100% !important;
      }
    }

    .member {
      font-family: 'Josefin Sans', sans-serif;
      margin: 40px 0;
      padding: 0 7.5px;
      width: 25%;

      &__long-description {
        text-align: center;
      }

      &__social {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        i {
          color: white;
          font-size: 1.4rem;
          padding: 0 10px;

          &:hover {
            color: #ff006a;
          }
        }
      }

      &__avatar {
        width: 100%;

        img {
          width: 100%;
        }
      }

      &__name {
        text-align: center;
        //padding-top: 5px;
        margin: 15px 0;
        font-size: 1.4rem;
      }

      &__description {
        font-weight: 400;
        text-align: center;
        font-size: .9rem;
        line-height: 25px;
        margin: 5px 0;
      }
    }
  }

  &__heading {
    width: 100%;
    padding: 0 50px;
    max-width: 100%;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    margin: 80px auto;

    h1 {
      text-transform: uppercase;
      font-size: 40px;
    }

    p {
      font-size: 19px;

      a {
        color: #c36;
        text-decoration: none;

      }
    }


  }

  &__item {
    background-color: #333;
    border: 5px solid white;
    margin: 50px 0;
    background-size: cover;
    background-position: center;

    .box {
      background: black;
      border: 5px solid white;
      max-width: 400px;
      margin: 100px auto;
      text-align: center;
      padding: 30px;

      h1 {
        font-family: 'Press Start 2P', cursive;
        font-size: 2rem;
        @media screen and(max-width: 468px) {
          font-size: 1.3rem;
        }
      }

      h5 {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 1.1rem;
        @media screen and(max-width: 468px) {
          font-size: .9rem;
        }
      }

      button {
        font-family: 'Press Start 2P', cursive;
        background: black;
        border: 5px solid white;
        color: white;
        padding: 10px 30px;

        &:hover {
          cursor: pointer;
          background: #c36;
        }
      }
    }

  }

  &__error {
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    color: #c36;
    max-width: 1024px;
    width: 100%;
    margin: 0 80px auto;
  }
}
</style>

<script>
import axios from 'axios'

let api_url = process.env.VUE_APP_API_URL
export default {
  data: function () {
    return {
      team: null, //team is array of Members
      loading: true,
      api_url: api_url,
      error: null
    }
  },
  async mounted() {
    document.title = this.page_title + ' - Team'
    try {
      await axios.get(this.api_url + '/members?_sort=order').then(r => {
        this.team = r.data
        this.loading = false
      })
    } catch (e) {
      this.loading = false
      this.error = "Our servers are down. Please try again later."
    }
  }
}

</script>