<template>
  <section class="aliens">
    <div class="aliens__article">
      <h1>MetaLIEnS.3D</h1>
      <p>Final step on aLIEnS. Collectibles
        <router-link to="/collectibles/aliens">Roadmap 1.0</router-link>
        is Breeding.
      </p>
      <p>10,000 new Collectibles appear. Who are MetaLIEnS. 3D? What traits do they have? We will soon find out! </p>
    </div>
    <div class="roadmap-image">
      <img src="../../../assets/roadmap2.png" alt="Roadmap 2.0">
    </div>
    <div class="aliens__article">
      <h1 style="font-size: 1.3rem;">Roadmap 2.0</h1>
      <p>Every Collectible series has its own Roadmap. Every Roadmap ends with Breeding. New aLIEnS from another
        dimension arrive. A new series of Collectibles is initiated.</p>
      <p>A New Collectibles series starts a new Roadmap with new activities and social targets. Each Collectibles series
        will be connected to each other.</p>
      <p></p>
      <p style="padding-top: 20px; font-weight: bold; text-decoration: underline">Roadmap 2.0 | Sneak peak | Key
        stuff</p>
      <p>/Metaverse ready 3D Collectibles</p>
      <p>/Play2Earn NFT Arcade Game Platform | Virtual World where members can play as their NFT 3D models</p>
      <p>/Metaverse, Metaverse, Metaverse</p>
      <p>/100 ETH+ in Giveaways</p>
      <p>/Real life size MetaLIEnS. 3D models for Club members</p>
      <p>/So much more but don’t tell anyone</p>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.aliens {
  margin-top: 70px;
  .roadmap-image{
    margin-bottom: 50px;
  }
  &__article {
    h1 {
      color: #ff006a;
      padding-bottom: 40px;
    }
  }
}
</style>

<script>
export default {
  mounted() {
    document.title = this.page_title + ' - MetaLIEnS'
  }
}
</script>