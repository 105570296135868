<template>
  <header>
    <div class="header__bar">
      <div class="container">
        <h3 v-for="i in 30" v-bind:key="i">
          WHITELIST CLOSED | PUBLIC SALE 01/11 12 PM CET.
        </h3>
      </div>
    </div>
    <div class="header" :class="mobile_open ? 'header--top':''">
      <div class="header__logo">
        <router-link to="/" @click="mobile_open = false">
          <img src="../../assets/logo.png" alt="logo">
        </router-link>
      </div>
      <div class="header__hamburger" @click="mobile_open = !mobile_open">
        <i class="fas fa-bars"></i>
      </div>
      <div class="header__menu" :class="mobile_open ? 'display' : 'd-lg-none'">
        <ul>
          <li v-for="link in router_links" :key="link.name">
            <router-link :to="link.path" :class="$route.path === link.path ? 'active' : ''"
                         @click="mobile_open = false">
              {{ link.name }}
              <i class="fa fa-caret-down" v-if="link.sub"></i>
            </router-link>
            <div v-if="link.sub" class="menu-dropdown">
              <ul>
                <li v-for="sublink in link.sub" :key="sublink.name">
                  <router-link :to="sublink.path" :class="$route.path === sublink.path ? 'active' : ''"
                               v-if="sublink.path">
                    {{ sublink.name }}
                  </router-link>
                  <a v-else>{{ sublink.name }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="saihoko">
              <img src="../../assets/saihokonft.png" alt="saihokonft">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  max-width: 100%;
  width: 100%;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  font-family: 'Josefin Sans', sans-serif;
  margin: 30px auto 0px;
  z-index: 100;
  border-bottom: 1px solid white;
  @media screen and(max-width: 992px) {
    align-items: center;
    padding: 20px;
  }

  &__bar {
    background: #ff006a;
    padding: 5px 10px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    z-index: 2;

    .container {
      display: flex;
      position: relative;
      animation: slide infinite 60s;
      @keyframes slide {
        from {
          left: 0px;
        }
        50% {
          left: -1000px;
        }
        to {
          left: 0px;
        }
      }

      h3 {
        margin: 0;
        font-size: .75em;
        font-family: 'Josefin Sans', sans-serif;
        padding: 0 20px;
      }

      width: 20000px;
    }
  }

  &--top {
    position: fixed;
    top: 0;
  }

  &__logo {
    max-width: 200px;
    z-index: 6;

    img {
      width: 100%;
    }
  }

  &__hamburger {
    z-index: 6;
    display: none;
    @media screen and(max-width: 992px) {
      display: block;
    }

    i {
      font-size: 2rem;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 900px;
    margin-left: 40px;
    @media screen and(max-width: 992px) {
      //display: none;
      position: fixed;
      background: black;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      margin-left: 0;
      z-index: 5;
      flex-direction: column;
      ul {
        margin-top: 150px !important;
        flex-wrap: nowrap;
        flex-direction: column;

        li {
          display: flex;
          justify-content: center;
          padding: 20px 0 !important;

          .saihoko {
            justify-self: center;
          }
        }
      }
    }


    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: space-around;

      @media screen and(max-width: 992px) {
        flex-wrap: wrap;
        text-align: center;
        margin-top: 20px;
      }

      li {
        text-transform: uppercase;
        @media screen and(max-width: 992px) {
          padding: 10px 15px;
        }
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 100%;
          }
        }

        .saihoko {
          max-width: 140px;

          img {
            width: 100%;
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .menu-dropdown {
              display: flex;
              animation: fadeIn ease-in-out 500ms;
            }
          }
        }

        &:target, &:focus {
          .menu-dropdown {
            display: flex;
            animation: fadeIn ease-in-out 500ms;
          }
        }

        .menu-dropdown {
          display: none;

          &:hover {
            display: flex;
          }

          ul {
            z-index: 2;
            margin: 0;
            flex-direction: column;
            position: absolute;
            margin: 0 -10px;
            padding: 5px 10px;
            background: black;
            max-width: 150px;

            li {
              padding: 15px 5px;
              margin: 0;
              text-transform: none;

            }
          }
        }

        a {
          color: white;
          text-decoration: none;
          display: flex;
          justify-content: center;

          i {
            display: none;
            @media screen and(min-width: 992px) {
              display: block;
              margin-left: 5px;
            }
          }

          &:hover {
            color: #ff006a;
          }
        }

        .active {
          color: #ff006a;
        }
      }
    }
  }
}

.d-lg-none {
  @media screen and(max-width: 992px) {
    display: none;
  }
}
</style>
<script>
let app_url = 'http://localhost:8080/#'

export default {
  data: function () {
    return {
      mobile_open: false,
      router_links: [{
        path: '/collectibles/aliens/all',
        name: '👽 aLIEnS.',
        // sub: [{
        //   path: '/collectibles/aliens',
        //   name: 'aLIEnS.'
        // }, {
        //   path: '/collectibles/metaliens',
        //   name: 'MetaLIEnS. 3D'
        // }]
      },
        {
          path: '/members',
          name: '⭐ Members.',
        },
        {
          path: '/collectibles/aliens',
          name: '🛸 About.',
        }, {
          path: '/team',
          name: '🚀 Team.'
        }, {
          path: '/to-collectors',
          name: '📙 To collectors.'
        }]
    }
  },
  methods: {
    isPage(link) {
      return window.location.href.replace(app_url, '') === link;
    }
  },
  watch: {
    $route() {
      this.mobile_open = false;
    }
  }
}
</script>