<template>
  <div id="app">
    <section v-if="!loaded" :class="loading_class">
      <p>👽</p>
    </section>
    <section class="popup" :class="popup ?'':'hide'">
      <div class="popup__container">
        <span class="h2">PUBLIC SALE</span>
        <p class="h1">🛸 01/11 12 PM CET</p>
        <p>👽 Don't miss the opportunity to buy your aLIEn!</p>
        <button @click="popup = false">Got it!</button>
      </div>
    </section>
    <section class="app">
      <Header/>
      <router-view></router-view>
      <Footer/>
    </section>
  </div>
</template>

<script>

import Header from './components/site/Header'
import Footer from './components/site/Footer'

export default {
  name: 'App',
  data: function () {
    return {
      loaded: false,
      loading_class: 'loading',
      popup: true,
    }
  },
  components: {
    Header, Footer
  },
  mounted() {
    window.addEventListener("load", () => {
      setTimeout(() => {
        this.loading_class = 'loading fadeOut'
        setTimeout(() => {
          this.loaded = true;
        }, 1000)
      }, 500)
    });
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Press+Start+2P&display=swap');

.fadeOut {
  animation: fadeOut 1s;
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

* {
  box-sizing: border-box;
}

body {
  background: black;
  color: white;
  margin: 0;
  padding: 0;

}

p {
  line-height: 32px;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 999;
  top: 0;

  p {
    font-size: 8rem;
    animation: live 1s infinite;
    @keyframes live {
      from {
        opacity: 0;
      }
      50% {
        opacity: inherit;
      }
      to {
        opacity: 0;
      }
    }

  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;
  font-size: 12px;

  &__container {
    padding: 40px 30px;
    width: 100%;
    max-width: 600px;
    background: black;
    border: 5px solid #ff006a;
    border-radius: 5px;
    font-family: 'Josefin Sans', sans-serif;
    opacity: 1;
    display: flex;
    flex-direction: column;

    .h1 {
      font-size: 2em;
      color: #ff006a;
    }

    .h2 {
      font-size: 1.5em;
      font-family: 'Press Start 2P', sans-serif;
    }

    button {
      align-self: center;
      background: #ff006a;
      color: white;
      border: 2px solid white;
      border-radius: 5px;
      padding: 10px 30px;
      font-size: 1.2rem;
      font-family: 'Josefin Sans', sans-serif;
      margin-top: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    p {
      font-size: 1.2em;
    }
  }
}

.hide {
  opacity: 0;
  display: none;
}
</style>
