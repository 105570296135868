import Home from './components/pages/Home.vue'
import Collectibles from './components/pages/Collectibles.vue'
import Artworks from './components/pages/Artworks.vue'
import Aliens from './components/pages/static/Aliens.vue'
import Alien from './components/pages/Alien.vue'
import AliensAll from './components/pages/Aliens.vue'
import About from './components/pages/static/About.vue'
import ToCollectors from './components/pages/static/ToCollectors.vue'
import Team from './components/pages/static/Team.vue'
import Metaliens from './components/pages/static/Metaliens'
import Chapter from './components/pages/static/Chapter'
import NotFound from './components/pages/static/NotFound.vue'
import Members from './components/pages/Members'

let routes = [
    {
        path: '*', component: NotFound
    },
    {
        path: '/', component: Home
    }, {
        path: '/collectibles', component: Collectibles
    }, {
        path: '/artworks', component: Artworks
    }, {
        path: '/artworks/chapter', component: Chapter
    },
    {
        path: '/collectibles/aliens', component: Aliens
    },
    {
        path: '/collectibles/metaliens', component: Metaliens
    },
    {
        path: '/collectibles/aliens/all', component: AliensAll
    },
    {
        path: '/collectibles/alien/:id', component: Alien
    },
    {
        path: '/creator', component: About
    },
    // {
    //     path: '/contact', component: Contact
    // },
    // {
    //   path: '/whitelist', component: Whitelist
    // },
    {
        path: '/to-collectors', component: ToCollectors
    },
    {
        path: '/team', component: Team
    },
    {
        path: '/members', component: Members
    }
]

/*
* Redirects
* Redirects are needed due to frozen links on OpenSea :/
*/

let redirects = [
    {
        path: '/index.php/aliens', redirect: '/collectibles/aliens'
    },
    {
        path: '/index.php/all-aliens', redirect: '/collectibles/aliens/all'
    },
    {
        path: '/index.php/metaliens-3d/', redirect: '/collectibles/metaliens'
    },
    {
        path: '/index.php/artworks', redirect: '/artworks'
    },
    {
        path: '/index.php/chapter-1', redirect: '/artworks/chapter'
    },
    {
        path: '/index.php/team', redirect: '/team'
    },
    {
        path: '/index.php/to-collectors', redirect: '/to-collectors'
    },
    {
        path: '/index.php/about', redirect: '/about'
    },
    {
        path: '/index.php/contact', redirect: '/contact'
    }
]

/* Add redirects to routes */
for (let redirect of redirects) routes.push(redirect)

/*
* Push redirects for each alien (range 1-1000)
* Ex: https://buzzle-art.com/index.php/alien/2 => https://buzzle-art.com/collectibles/alien/2
*/
for (let i = 1; i <= 1000; i++) {
    let redirect = {
        path: '/index.php/alien/' + i,
        redirect: '/collectibles/alien/' + i
    }

    routes.push(redirect);
}


/* Export routes for router */
export default routes